import React, { useState } from "react";
import "./Retails.scss";
import back_img from "../../img/retails.webp";
import Form from "../../components/form/Form";

const Retails = () => {
  

  const resetForm = () => {};
  return (
    <div className="__retails_con">
      <div
        className="__re_head"
        style={{
          backgroundImage: `url(${back_img})`,
          backgroundSize: "cover",
          backgroundPosition: "bottom",
        }}
      >
        <h1>Distribution Partners</h1>
      </div>

      <div className="__container">
        <div className="__re_wrap">
          <h1>Where Relationships Meet Reach</h1>
          <p>
            Our portfolio of retail partners speaks to the level of trust and
            professionalism we bring to our operations. In the UAE, we proudly
            partner with retail behemoths like Carrefour, LuLu, Spinneys, and
            Waitrose. Meanwhile, our footprint in KSA includes an equally
            impressive roster featuring Panda, Tamimi, and Bin Dawood.
          </p>
        </div>

        <div className="__re_logos">
          <div className="__re_logo_icon">
            <img src={require("../../img/c_f.png")} />
            <h3>Over 1,200 outlets across the Middle East region</h3>
          </div>

          <div
            className="__re_logo_icon"
            style={{ backgroundColor: "rgb(249, 249, 249)" }}
          >
            <img src={require("../../img/lulu.png")} />
            <h3>Operating in 22 countries with more than 200 stores.</h3>
          </div>

          <div className="__re_logo_icon">
            <img src={require("../../img/sp.png")} />
            <h3>A high-end retailer with 59 outlets in the UAE.​</h3>
          </div>
          <div
            className="__re_logo_icon"
            style={{ backgroundColor: "rgb(249, 249, 249)" }}
          >
            <img src={require("../../img/w_r.png")} />
            <h3>9 premium stores in the UAE.​</h3>
          </div>

          <div
            className="__re_logo_icon"
            style={{ backgroundColor: "rgb(249, 249, 249)" }}
          >
            <img src={require("../../img/pnda.png")} />
            <h3>
              One of the largest grocery chains in KSA with over 200
              locations.​​
            </h3>
          </div>

          <div className="__re_logo_icon">
            <img src={require("../../img/t_m.png")} />
            <h3>Over 40 supermarkets in KSA.​</h3>
          </div>

          <div
            className="__re_logo_icon"
            style={{ backgroundColor: "rgb(249, 249, 249)" }}
          >
            <img src={require("../../img/b_w.png")} />
            <h3>73 stores across key cities in KSA.​​</h3>
          </div>
        </div>

        <div className="__re_form_wrap">
          <div className="__re_content">
            <h1>
              Why <span>Notions Trading </span>Stands Unmatched
            </h1>
            <br />
            <br />
            <div className="__re_con_in_wrap">
              <div>
                <h2>Trade Penetration</h2>
                <p>
                  Our partnerships with these retail chains aren't just about
                  numbers but depth. We have excellent trade penetration,
                  allowing our products to reach the farthest corners of these
                  expansive networks.​
                </p>
              </div>
              <div className="__re_con_in">
                <h2>Trusted Relationships</h2>
                <p>
                  Our retail partners trust us for our consistent quality,
                  reliability, and market understanding, making us the preferred
                  choice for FMCG distribution.​
                </p>
              </div>
            </div>
          </div>
          <div className="__re_form">
            <Form/>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default Retails;
