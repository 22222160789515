import React from "react";
import "./catalog.scss";
import HTMLFlipBook from "react-pageflip";
import "./catalog.scss";
import catalog from "../../img/New Luxury Chocolate/Luxury Chocolate_compressed.pdf";
import Form from "../../components/form/Form";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Link } from "react-router-dom";

const CatalogPageTwo = () => {
  return (
    <div className="catalog __container">
      <Link style={{ color: "black" }} to="/index.php/catalog">
        <IoMdArrowRoundBack size={30} color="black" /> Back
      </Link>

      <div className="book">
        <HTMLFlipBook width={350} height={550}>
          <div className="demoPage">
            <img
              src={require("../../img/New Luxury Chocolate/Cover Page LUX New P1.webp")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Luxury Chocolate/Cover Page LUX New P2.webp")}
              alt=""
            />
          </div>
          {/* <div className="demoPage">
            <img
              src={require("../../img/New Luxury Chocolate/Luxury Chocolate-01.webp")}
              alt=""
            />
          </div> */}
          <div className="demoPage">
            <img
              src={require("../../img/New Luxury Chocolate/Luxury Chocolate-02.webp")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Luxury Chocolate/Luxury Chocolate-03.webp")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Luxury Chocolate/Luxury Chocolate-04.webp")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Luxury Chocolate/Luxury Chocolate-05.webp")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Luxury Chocolate/Luxury Chocolate-06.webp")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Luxury Chocolate/Luxury Chocolate-07.webp")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Luxury Chocolate/Luxury Chocolate-08.webp")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Luxury Chocolate/Luxury Chocolate-09.webp")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Luxury Chocolate/Luxury Chocolate-10.webp")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Luxury Chocolate/Luxury Chocolate-11.webp")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Luxury Chocolate/Luxury Chocolate-12.webp")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Luxury Chocolate/Luxury Chocolate-13.webp")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Luxury Chocolate/Luxury Chocolate-14.webp")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Luxury Chocolate/Luxury Chocolate-15.webp")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Luxury Chocolate/Luxury Chocolate-16.webp")}
              alt=""
            />
          </div>

          <div className="demoPage">
            <img
              src={require("../../img/New Luxury Chocolate/Luxury Chocolate-17.webp")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Luxury Chocolate/Luxury Chocolate-18.webp")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Luxury Chocolate/Luxury Chocolate-19.webp")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Luxury Chocolate/Luxury Chocolate-20.webp")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Luxury Chocolate/Luxury Chocolate-21.webp")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Luxury Chocolate/Luxury Chocolate-22.webp")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Luxury Chocolate/Luxury Chocolate-23.webp")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Luxury Chocolate/Luxury Chocolate-24.webp")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Luxury Chocolate/Luxury Chocolate-25.webp")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Luxury Chocolate/Luxury Chocolate-26.webp")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Luxury Chocolate/Luxury Chocolate-27.webp")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Luxury Chocolate/Luxury Chocolate-28.webp")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Luxury Chocolate/Luxury Chocolate-29.webp")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Luxury Chocolate/Luxury Chocolate-30.webp")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Luxury Chocolate/Luxury Chocolate-31.webp")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Luxury Chocolate/Luxury Chocolate-32.webp")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Luxury Chocolate/Luxury Chocolate-33.webp")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Luxury Chocolate/Luxury Chocolate-34.webp")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Luxury Chocolate/Luxury Chocolate-35.webp")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Luxury Chocolate/Luxury Chocolate-36.webp")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Luxury Chocolate/Luxury Chocolate-37.webp")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Luxury Chocolate/Luxury Chocolate-38.webp")}
              alt=""
            />
          </div>
          <div className="demoPage">
            <img
              src={require("../../img/New Luxury Chocolate/Luxury Chocolate-39.webp")}
              alt=""
            />
          </div>
          {/* <div className="demoPage">
            <img
              src={require("../../img/New Luxury Chocolate/Luxury Chocolate-40.webp")}
              alt=""
            />
          </div> */}
          {/* <div className="demoPage">
            <img
              src={require("../../img/New Luxury Chocolate/Luxury Chocolate-41.webp")}
              alt=""
            />
          </div> */}
        </HTMLFlipBook>
        <br />
        <div>
          <h3>Click on book to open </h3>
          <img
            className="icon_img"
            style={{ width: "80px" }}
            src={require("../../img/swipe-left.gif")}
            alt=""
          />
        </div>
        <div className="down">
          <Form />
          <div className="cat_down">
            <h1></h1>

            <h3>
              Click on below button to download <br /> PDF file of Catalog
            </h3>

            <a
              href={catalog}
              download="Catalog"
              target="_blank"
              rel="noreferrer"
            >
              <button className="btn">Download PDF</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CatalogPageTwo;
