import React, { useState } from "react";
import "./Confectionery.scss";
import ConfectioneryBreandHead from "../confectioneryBrand/ConfectioneryBreandHead";

const Confectionery = () => {

  

  const [isShowMoreA, setIsShowMoreA] = useState(false);
  const [isShowMoreB, setIsShowMoreB] = useState(false);
  const [isShowMoreC, setIsShowMoreC] = useState(false);
  const [isShowMoreD, setIsShowMoreD] = useState(false);
  const [isShowMoreE, setIsShowMoreE] = useState(false);

  const toggleReadMoreLessA = () => {
    setIsShowMoreA(!isShowMoreA);
  };
  const toggleReadMoreLessB = () => {
    setIsShowMoreB(!isShowMoreB);
  };
  const toggleReadMoreLessC = () => {
    setIsShowMoreC(!isShowMoreC);
  };
  const toggleReadMoreLessD = () => {
    setIsShowMoreD(!isShowMoreD);
  };
  const toggleReadMoreLessE = () => {
    setIsShowMoreE(!isShowMoreE);
  };

  return (
    <div className="confectionery_co">
      <div style={{paddingTop:"50px"}}>
        <ConfectioneryBreandHead />
      </div>

      <div className="__container">
        <div className="__con_head">
          
          <p>
            The ever expanding multiverse of Notions Confectionary now includes​
          </p>
        </div>
        <div className="__con_main_cat_con">
          <a href="#1">
            <div className="__con_cat_card">
              <div className="__con_cat_img">
                <img src={require("../../img/con_01.webp")} />
              </div>
              <div className="__con__cat_text">
                <h2>Chocodates</h2>​
              </div>
            </div>
          </a>

          <a href="#3">
            <div className="__con_cat_card">
              <div className="__con_cat_img">
                <img src={require("../../img/con_03.webp")} />
              </div>
              <div className="__con__cat_text">
                <h2>Protein Balls​</h2>​
              </div>
            </div>
          </a>

          <a href="#4">
            <div className="__con_cat_card">
              <div className="__con_cat_img">
                <img src={require("../../img/con_04.webp")} />
              </div>
              <div className="__con__cat_text">
                <h2>One Shot Chocolates​</h2>​
              </div>
            </div>
          </a>

          <a href="#5">
            <div className="__con_cat_card">
              <div className="__con_cat_img">
                <img src={require("../../img/con_05.webp")} />
              </div>
              <div className="__con__cat_text">
                <h2>Gourmet Chocolates​​</h2>​
              </div>
            </div>
          </a>

          <a href="#6">
            <div className="__con_cat_card">
              <div className="__con_cat_img">
                <img src={require("../../img/con_06.webp")} />
              </div>
              <div className="__con__cat_text">
                <h2>Chocolate Clusters​​</h2>​
              </div>
            </div>
          </a>
        </div>
      </div>

      <div style={{ backgroundColor: "#f9f9f9" }}>
        <div className="conf_wrap __container" id="1">
          <div className="conf_img I_a">
            <img src={require("../../img/chocolate 2.webp")} />
          </div>
          <div className="conf_txt I_b">
            <div className="conf_text_head">
              <h1>Chocodates</h1>
              <h3>Chocodate, a true emblem of NOTIONS Group</h3>
            </div>
            <p>
              Chocodate, a true emblem of NOTIONS Group, stands as a testament
              to the company's innovative spirit.
              <br />
            </p>
            {isShowMoreA && (
              <p className="__text">
                This pioneering creation embarked on its journey in 1992,
                conceived by Mr. Fawaz Masri himself. Combining the richness of
                Belgian chocolate coating with the delightful texture of almonds
                sourced from the USA, Chocodate has become synonymous with
                premium, healthy snacking.
                <br />
                NOTIONS Group's mastery in this category is evident, as it
                commands an impressive 50%+ market share in the UAE. As the
                proud creator and sole proprietor of this unique product
                category, the company's dedication to excellence and consumer
                satisfaction shines through. Over the years, responding to
                consumer preferences and market demands, NOTIONS has introduced
                multiple flavors and variants of Chocodate.
                <br />
                <br />
                These accomplishments reflect not only the brand's dominance in
                the market but also its responsiveness to its customers' tastes.
                As a leader and innovator, NOTIONS continues to set industry
                standards, ensuring that Chocodate remains a cherished favorite
                for those seeking a luxurious yet health-conscious treat.
              </p>
            )}
            <button onClick={toggleReadMoreLessA}>
              {isShowMoreA ? "Read Less" : "Read More"}
            </button>
          </div>
        </div>
      </div>

      <div>
        <div className="conf_wrap __container" id="4">
          <div className="conf_txt I_c">
            <div className="conf_text_head">
              <h1>One Shot Chocolates</h1>
              <h2>Presenting a sophisticated range of chocolates</h2>
            </div>
            <p>
              Presenting a sophisticated range of chocolates, our collection of
              incomparable and iconic flavors is a testament to the art of
              chocolate-making.
            </p>
            {isShowMoreB && (
              <p className="__text">
                Each flavor, enrobed in luscious Belgian chocolate, offers an
                unparalleled experience of smooth and mouth-watering indulgence.
                This finest assortment delivers a moment of pure bliss, dazzling
                the senses and providing an ultimate pleasure that appeals to
                chocolate lovers of all ages.​
                <br />
                <br />
                ​ Crafted in Notions Manufacturing's state-of-the-art factory in
                the UAE, this wide variety and collection cater to all, from
                kids to adults. Utilizing superior quality European Chocolates,
                we create exquisite fillings such as praline, truffles, fruit
                and nut, and caramel. Each creation is carefully designed to
                suit both local and international markets, embodying a blend of
                tradition and innovation. Notions has the capacity to produce
                1.8 Mertric Tons per Day
                <br />
              </p>
            )}
            <button onClick={toggleReadMoreLessB}>
              {isShowMoreB ? "Read Less" : "Read More"}
            </button>
          </div>
          <div className="conf_img I_d">
            <img src={require("../../img/one_shot.webp")} />
          </div>
        </div>
      </div>

      <div style={{ backgroundColor: "#f9f9f9" }}>
        <div className="conf_wrap  __container" id="5">
          <div className="conf_img I_e">
            <img src={require("../../img/gourmet.webp")} />
          </div>
          <div className="conf_txt I_f">
            <div className="conf_text_head">
              <h1>Gourmet Chocolates</h1>
              <h2>
                Notions Manufacturing stands at the intersection of tradition
              </h2>
            </div>
            <p>
              Notions Manufacturing stands at the intersection of tradition and
              innovation, crafting a distinguished line of unique gourmet
              chocolates.
            </p>
            {isShowMoreC && (
              <p className="__text">
                As a premier manufacturer, innovator, and chocolatier, the
                company prides itself on merging the artistry of
                chocolate-making with culinary creativity.
                <br />
                <br />
                Utilizing only the most luxurious ingredients, such as Belgian
                chocolate, hazelnuts, and pistachios, Notions Manufacturing's
                expert dessert chefs craft each chocolate with passion and
                precision. Their commitment to quality is evident in every bite,
                where the richness of flavor meets the smooth texture that only
                the finest ingredients can provide.
                <br />
                <br />
                But what truly sets Notions Manufacturing apart is their
                dedication to innovation. Their recipes are not confined to
                modern trends alone; instead, they celebrate a fusion of
                flavors, harmoniously blending tradition with contemporary
                tastes. This inventive approach results in chocolates that are
                not merely confections but culinary masterpieces, each telling a
                story of heritage and imagination.​
              </p>
            )}
            <button onClick={toggleReadMoreLessC}>
              {isShowMoreC ? "Read Less" : "Read More"}
            </button>
          </div>
        </div>
      </div>

      <div>
        <div className="conf_wrap  __container" id="6">
          <div className="conf_txt I_i">
            <div className="conf_text_head">
              <h1>Chocolate Clusters</h1>
              <h2>Clusters Is a unique inimitable harmonious blend</h2>
            </div>
            <p>
              Notions Manufacturing also plays in the category of chocolate
              clusters. Clusters Is a unique inimitable harmonious blend..
            </p>
            {isShowMoreD && (
              <p className="__text">
                of carefully high-end selected ingredients covered with a
                delightful layers of premium Belgium chocolates to offer you a
                surprising taste experience.
                <br />
                <br />
                The delicious chocolate flavors of each of the bite sized
                delicacies & unique fillings make them the perfect chocolate
                gift for any occasion. Notions has the capacity to produce 18
                Metric Ton per day.
                <br />
              </p>
            )}
            <button onClick={toggleReadMoreLessD}>
              {isShowMoreD ? "Read Less" : "Read More"}
            </button>
          </div>
          <div className="conf_img I_j">
            <img src={require("../../img/clusters.webp")} />
          </div>
        </div>
      </div>

      <div style={{ backgroundColor: "#f9f9f9" }}>
        <div className="conf_wrap __container" id="3">
          <div className="conf_img I_k">
            <img src={require("../../img/protine_ball.webp")} />
          </div>
          <div className="conf_txt I_l">
            <div className="conf_text_head">
              <h1>Protein Balls</h1>
              <h2> With the advent of health and Wellness</h2>
            </div>
            <p>
              With the advent of health and Wellness, there has been a great
              change in the consumer behavior towards snacks.
              <br />
            </p>
            {isShowMoreE && (
              <p>
                Consumers, in their pursuit for a guilt free snacking are always
                looking for a healthy options.
                <br />
                <br />
                Notion's R&D has always been on the forefront of innovation.
                Protein Balls is one such development from Notions. We can pr  
              </p>
            )}
            <button onClick={toggleReadMoreLessE}>
              {isShowMoreE ? "Read Less" : "Read More"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
  
};

export default Confectionery;
