import React from "react";
import "./About.scss";
import back_img_about from "../../img/back_img.webp";
import pvt_img from "../../img/about.webp";
import banner from "../../img/banner.jpeg";
import { Link } from "react-router-dom";
import Nav_map from "../../components/nav_map/Nav_map";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import Diversity2Icon from "@mui/icons-material/Diversity2";
import SatelliteAltIcon from "@mui/icons-material/SatelliteAlt";
import CenterFocusStrongIcon from "@mui/icons-material/CenterFocusStrong";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import { Backdrop } from "@mui/material";
import LocalFloristIcon from "@mui/icons-material/LocalFlorist";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import LuggageIcon from "@mui/icons-material/Luggage";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import AddReactionIcon from "@mui/icons-material/AddReaction";
import ForwardIcon from "@mui/icons-material/Forward";
import TimeLine from "../../components/timeLine/TimeLine";

const About = () => {
  return (
    <div className="__about_wrap">
      <div
        className="__pvt_head"
        style={{
          backgroundImage: `url(${pvt_img})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <h1>
          <span> About Us</span>​
        </h1>
      </div>

      <div className="__about_head_text __container">
        <h2 style={{ paddingBottom: "20px" }}>
          <span>Notions Group</span> is a dynamic group of companies innovating,
          manufacturing
        </h2>
        <p>
          marketing and distributing brands that our consumers have grown to
          love. Headquartered in the ever-changing city of Dubai, we focus on
          building confectionary, date and snack brands that reflect both
          heritage and modernity. The journey started in 1992 when our CEO,
          Fawaz Masri created Dubai’s original guilt-free Chocodate, which has
          become the chosen healthy & delicious treat for enlightened consumers
          globally. Since then it has been a continuous journey of successful
          innovations one after the other.
        </p>
      </div>
      <div className="__menegment__wrap">
        <div className="__container">
          <h1>
            Leadership <span>Team</span>
          </h1>
          <div className="__menegment__wrap__in">
            <div className="__menegment_card">
              <img src={require("../../img/img_01.jpeg")} />
              <span>Fawaz Masri</span>
              <p>Founder & CEO</p>
            </div>

            <div className="__menegment_card">
              <img src={require("../../img/img_02.jpeg")} />
              <span>Razan Masri</span>
              <p>Chief Business Development Officer Notions Global</p>
            </div>

            <div className="__menegment_card">
              <img src={require("../../img/img_03.jpeg")} />
              <span>Omar Masri</span>
              <p>Cheif Operating Officer Notions Global</p>
            </div>

            <div className="__menegment_card">
              <img src={require("../../img/img_04.jpeg")} />
              <span>Hazem Masri</span>
              <p>
                Operations Manager, <br /> Snacks Division
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="__time__line">
        <div className="__container">
          <div className="__time__head_sec">
            <h1>
              Our Journey <span>Tradition and Modernity combined</span> ​
            </h1>
          </div>
          <div className="__time__contents">
            <div className="__time_top">
              <div className="__time_card_min">
                <div className="__time_card_out">
                  <div className="__round"></div>
                </div>
                <div className="__time__card">
                  <LocalFloristIcon className="__icon" />
                  <h1>1991</h1>
                  <h3>Journey started</h3>
                  <p>
                    The journey started in 1991 when our CEO, Fawaz Masri
                    founded La Ronda Chocolate factory.
                  </p>
                </div>
              </div>
              <div className="__time_card_min">
                <div className="__time_card_out">
                  <div className="__round"></div>
                </div>
                <div className="__time__card">
                  <LooksOneIcon className="__icon" />
                  <h1>1992</h1>
                  <h3>1st ever Chocodate</h3>
                  <p>
                    A unique delicacy was invented for the very first time by
                    inserting an Almond inside a date and enrobing with belgian
                    chocolate by Mr Fawaz Masri himself
                  </p>
                </div>
              </div>
              <div className="__time_card_min">
                <div className="__time_card_out">
                  <div className="__round"></div>
                </div>
                <div className="__time__card">
                  <LuggageIcon className="__icon" />
                  <h1>1993</h1>
                  <h3>Dubai Duty Free</h3>
                  <p>
                    Chocodate became an instant success and was recognised by
                    tourists from around the world as a part of Dubai itself ​
                  </p>
                </div>
              </div>
            </div>

            <div className="__time__center__line">
              <div className="__round_two"></div>
              <div className="__round"></div>
            </div>

            <div className="__time_bottom">
              <div className="__time_card_min">
                <div className="__time_card_out">
                  <div className="__round"></div>
                </div>
                <div className="__time__card">
                  <FlightTakeoffIcon className="__icon" />
                  <h1>1992</h1>
                  <h3>Export</h3>
                  <p>
                    The demand for chocodate grew with the flow of tourists.
                    Starting with 1 country at a time, Notions grew its presence
                    to 50+ countries​
                  </p>
                </div>
              </div>

              <div className="__time_card_min">
                <div className="__time_card_out">
                  <div className="__round"></div>
                </div>
                <div className="__time__card">
                  <AddReactionIcon className="__icon" />
                  <h1>2023</h1>
                  <h3>The confectionary and snacks expert</h3>
                  <p>
                    Notions Group with its experience over the years stands tall
                    as an expert in Confectionary and Snacks with the capability
                    to produce multiple categories.​
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="__companices ">
        <div className="__container">
          <div className="___companices__head">
            <h1>
              Group of <span>companies</span>
            </h1>
          </div>
          <div className="__companies__wrap">
            <div className="__company_card">
              <div className="__company_logo">
                <img src={require("../../img/Star Food Logo.png")} />
              </div>
              <div className="company_text">
                <p>
                  Star Food industry, established in 1990, produces diverse and
                  tasty chips and safe snack food. ​ The fully automated
                  production process, from raw material input to final
                  packaging, operates without any physical human contact.​
                </p>
              </div>
            </div>

            <div className="__company_card">
              <div className="__company_logo">
                <img src={require("../../img/NOTIONS TRADING KSA.png")} />
              </div>
              <div className="company_text">
                <p>
                  Notions Trading KSA is responsible for the distribution of all
                  La Ronda and SFI products In Saudi Arabia​ With a tem of 93+
                  professionals and a sizeable fleet, Notions Trading covers
                  1000+Direct doors in 16+ cities across KSA. ​ ​ ​
                </p>
              </div>
            </div>

            <div className="__company_card">
              <div className="__company_logo">
                <img src={require("../../img/NOTIONS MANUFACTURING.png")} />
              </div>
              <div className="company_text">
                <p>
                  Notions Manufacturing is a chocolate manufacturing concern in
                  Dubai. ​ It a specialized unit that produces gourmet
                  chocolates and Malban.​ ​ Notions manufacturing is a state of
                  the art manufacturing unit which has the capability of
                  customizing the products according to customer needs.​ ​ ​ ​ ​
                </p>
              </div>
            </div>

            <div className="__company_card">
              <div className="__company_logo">
                <img src={require("../../img/La-Ronda Logo 2.png")} />
              </div>
              <div className="company_text">
                <p>
                  La Ronda Emirates LLC, a highly diversified Chocolate and
                  Chocodate manufacturing company based in Dubai which
                  established in 1991.​ ​ It’s using state of the art of one
                  shot molding machine. The factory is comprised of the
                  Chocolate and Chocodate processing plant, with a total
                  processing capacity of 183mt per Annum with product size of 10
                  to 16gms. La Ronda Emirates is an ISO and HACCP certified
                  company.
                </p>
              </div>
            </div>

            <div className="__company_card">
              <div className="__company_logo">
                <img src={require("../../img/NOTIONS TRADING UAE .png")} />
              </div>
              <div className="company_text">
                <p>
                  Notions Trading UAE is the distribution arm of notions group.
                  With a fleet of 11+ delivery vehicles, dedicated sales team
                  comprising of salesman, supervisors and merchandisers, Notions
                  Trading distributes to all the major channels in UAE
                </p>
              </div>
            </div>

            <div className="__company_card">
              <div className="__company_logo">
                <img src={require("../../img/NOTIONS TRADING KSA.png")} />
              </div>
              <div className="company_text">
                <p>
                  Notions Global is an entity that is responsible for exports of
                  all Notion Group Brands around the world. The company boasts a
                  presence of more than 50 countries world wide.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="__container">
        <div className=" __about__mission">
          <div className="__about_item_warp">
            <div className="__about__item">
              <FavoriteIcon className="__icon" />
              <h2>
                Reason and <span>Passion</span>
              </h2>
              <p>
                Driven by passion and guided by reason, we at Notions invest our
                hearts and minds in every aspect of our business. This
                dedication ensures optimal results. The love we hold for our
                brands, products, customers, and team fuels our drive, and our
                rational approach ensures our success.
              </p>
            </div>
            <div className="__about__item">
              <SatelliteAltIcon className="__icon" />

              <h2>
                Tradition and <span>Modernity</span>
              </h2>
              <p>
                Our products are inspired by ancestral values while embracing
                future innovations. Notions specializes in taking time-honored
                practices and infusing them with contemporary flair. By honoring
                what has been proven over time and enhancing it with modern
                twists, we create signature offerings.
              </p>
            </div>

            <div className="__about__item">
              <Diversity2Icon className="__icon" />
              <h2>
                Family and <span>Community</span>
              </h2>
              <p>
                Notions operates as a family-run enterprise, maintaining family
                values at its core, all to benefit the community we serve. We
                consider our team a family, each member playing a vital role.
                Through trust, stability, creativity, and spontaneity, we foster
                a motivating environment.
              </p>
            </div>
          </div>

          <div className="__about_item_warp">
            <div className="__about__item">
              <CenterFocusStrongIcon className="__icon" />
              <h2>
                Vision and <span> Focus</span>
              </h2>
              <p>
                While our vision spans wide, our focus remains sharply defined.
                Our shared ambition is to make Notions' products a global
                favorite. Though our goals are expansive, our path is carefully
                chosen, allowing us to be something unique to those we serve
                rather than attempting to be all things to all people.
              </p>
            </div>
            <div className="__about__item">
              <EmojiEmotionsIcon className="__icon" />
              <h2>
                Excellence and <span>Satisfaction</span>
              </h2>
              <p>
                The pursuit of excellence at Notions begins with employee
                satisfaction. We believe that exceptional outcomes are born from
                content and fulfilled team members. To this end, we maintain
                open lines of communication between management and staff,
                fostering growth through continuous education and training.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        className="__about__vision"
        style={{
          backgroundImage: `url(${back_img_about})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="__container">
          <div className="__about__items">
            <div className="__vision_item">
              <div className="__vision_item_in">
                <h1>Vision </h1>
                <p>
                  "To become a global symbol of innovation and quality in the
                  confectionery, date, and snack industry, merging tradition
                  with modernity, and crafting products that inspire delight and
                  wellness. Our vision is to make Notions' offerings a preferred
                  choice worldwide, reflecting our commitment to excellence,
                  community, and sustainability."
                </p>
              </div>
            </div>

            <div className="__vision_item">
              <div className="__vision_item_in">
                <h1>Mission </h1>
                <p>
                  "At Notions Group, we are dedicated to creating and nurturing
                  brands that resonate with both heritage and contemporaneity.
                  Driven by a passion for excellence and a strong family ethos,
                  our mission is to produce guilt-free and delicious treats that
                  cater to enlightened consumers across the globe. We strive to
                  foster creativity and satisfaction within our team, build
                  trust with our customers, and contribute positively to the
                  community, all while honoring the values handed down from our
                  ancestors."
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*

      <div className=" __time_line">
        <div className="__container">
          <div className="__timeline__wrap">
            <h1>
              Our Journey <span>Tradition and Modernity combined</span> ​
            </h1>

            <div className="__time_line_cards">
              <div className="__time_line_card">
                <LocalFloristIcon className="__icon" />
                <div className="__arrow" style={{ backgroundColor: "#59c1a4" }}>
                  <div className="__arrow_item">
                    <span>1991</span>
                  </div>
                  <div className="__arrow_item">
                    <ForwardIcon />
                  </div>
                </div>
                <h2>​ journey started </h2>
                <p>
                  The journey started in 1991 when our CEO, Fawaz Masri founded
                  La Ronda Chocolate factory. ​
                </p>
              </div>

              <div className="__time_line_card">
                <LooksOneIcon className="__icon" />

                <div className="__arrow" style={{ backgroundColor: "#6dcad2" }}>
                  <div className="__arrow_item">
                    <span>1992 </span>
                  </div>
                  <div className="__arrow_item">
                    <ForwardIcon />
                  </div>
                </div>

                <h2>​ 1st ever Chocodate</h2>
                <p>
                  A unique delicacy was invented for the very first time by
                  inserting an Almond inside a date and enrobing with belgian
                  chocolate by Mr Fawaz Masri himself ​
                </p>
              </div>

              <div className="__time_line_card">
                <LuggageIcon className="__icon" />

                <div className="__arrow" style={{ backgroundColor: "#2fabd4" }}>
                  <div className="__arrow_item">
                    <span>1992 </span>
                  </div>
                  <div className="__arrow_item">
                    <ForwardIcon />
                  </div>
                </div>
                <h2>Dubai Duty Free​</h2>
                <p>
                  Chocodate became an instant success and was recognised by
                  tourists from around the world as a part of Dubai itself ​
                </p>
              </div>

              <div className="__time_line_card">
                <FlightTakeoffIcon className="__icon" />
                <div className="__arrow" style={{ backgroundColor: "#104574" }}>
                  <div className="__arrow_item">
                    <span style={{ color: "white" }}>1992 </span>
                  </div>
                  <div className="__arrow_item">
                    <ForwardIcon style={{ color: "white" }} />
                  </div>
                </div>
                <h2>Export​</h2>
                <p>
                  The demand for chocodate grew with the flow of tourists.
                  Starting with 1 country at a time, Notions grew its presence
                  to 50+ countries​
                </p>
              </div>

              <div className="__time_line_card">
                <AddReactionIcon className="__icon" />

                <div className="__arrow" style={{ backgroundColor: "#8a1540" }}>
                  <div className="__arrow_item">
                    <span style={{ color: "white" }}>1992 </span>
                  </div>
                  <div className="__arrow_item">
                    <ForwardIcon style={{ color: "white" }} />
                  </div>
                </div>

                <h2>The confectionary and snacks expert​</h2>

                <p>
                  Notions Group with its experience over the years stands tall
                  as an expert in Confectionary and Snacks with the capability
                  to produce multiple categories.​
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      */}
    </div>
  );
};

export default About;
