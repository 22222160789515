import React, { useState } from "react";
import "./PrivateLabels.scss";
import pvt_img from "../../img/pvt_banner.webp";
import BarChartRoundedIcon from "@mui/icons-material/BarChartRounded";
import PinchRoundedIcon from "@mui/icons-material/PinchRounded";
import VerifiedUserRoundedIcon from "@mui/icons-material/VerifiedUserRounded";
import Form from "../../components/form/Form";

const PrivateLabels = () => {
  

  return (
    <div className="__private_labels">
      <div
        className="__pvt_head"
        style={{
          backgroundImage: `url(${pvt_img})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <h1>
          Private Labels <span>Partners</span>​
        </h1>
      </div>

      
      <div className="__container">
        <div className="__pvt__section">
          <div className="__pvt_section">
            <h2>
              At Notions, we pride ourselves on our extensive private and white
              labeling capabilities, allowing us to cater to a diverse range of
              clients and markets.
            </h2>
          </div>
          <div className="__pvt_section">
            <p>
              Our innovative solutions in the chocolate confectionary sector
              enable our partners to leverage our production excellence,
              creative ingenuity, and unparalleled quality assurance. Notions is
              private Label partner in confectionary and snacking for more than
              30+ brands. ​ ​
            </p>
          </div>
        </div>
        <div className="__pvt_icon">
          <div className="__pvt_icon_section">
            <BarChartRoundedIcon
              style={{ fontSize: "70px", color: "gray", paddingBottom: "20px" }}
            />
            <h2>Market Insight​</h2>
            <br />
            <p>
              Our experienced R&D team stays abreast of the latest global trends
              in chocolate confectionery, enabling us to offer cutting-edge
              products​ we have an intrinsic understanding of regional
              preferences and regulations, ensuring that our products resonate
              with local tastes
            </p>
          </div>
          <div className="__pvt_icon_section">
            <PinchRoundedIcon
              style={{ fontSize: "70px", color: "gray", paddingBottom: "20px" }}
            />
            <h2>Production Scalability</h2>
            <br />
            <p>
              Whether you're a boutique brand needing small-batch production or
              a global name requiring large-scale manufacturing, our
              state-of-the-art facilities can accommodate your needs.
            </p>
          </div>
          <div className="__pvt_icon_section">
            <VerifiedUserRoundedIcon
              style={{ fontSize: "70px", color: "gray", paddingBottom: "20px" }}
            />
            <h2>Certified Excellence​</h2>
            <br />
            <p>
              Our manufacturing facilities are certified to international
              standards, ensuring that our private and white-label products meet
              stringent quality requirements
            </p>
          </div>
        </div>

        <div className="__pvt__retail">
          <div className="__pvt__form">
            <div>
              <h1>Private Label Partners ​</h1>
              <br />
              <p>
                Notions Group's expertise in private labeling is a journey
                marked by innovation, commitment, and unparalleled excellence.
                Originating from a humble partnership with the German retailer
                "Aldi," we have ascended to become a preferred choice for some
                of the most prestigious names across different channels. Our
                success is anchored in our flexible MOQs, comprehensive
                end-to-end packaging solutions, a proficient in-house design
                team, competitive pricing, and most importantly, a revolutionary
                R&D department that sets us apart in the market.
              </p>
            </div>
            <div>
              <div className="__exp__form__con">
                <Form/>

                
              </div>
            </div>
          </div>
          <div className="__pvt_retail_section">
            <div>
              <div className="__pvt_icon_section">
                <h2>Retail Sector Partnerships​</h2>
                <br />
                <div className="__icon_logos">
                  <img src={require("../../img/l15.webp")} />
                  <img src={require("../../img/l11.webp")} />
                  <img src={require("../../img/l12.webp")} />
                  <img src={require("../../img/l13.webp")} />
                  <img src={require("../../img/l14.webp")} />
                  <img src={require("../../img/l10.webp")} />
                  <img src={require("../../img/l16.webp")} />
                  <img src={require("../../img/l17.webp")} />
                  <img src={require("../../img/l18.webp")} />
                </div>

                <p>
                  In the UAE, we've forged private label collaborations with
                  esteemed retail brands like Carrefour, Waitrose, Lulu, and
                  Spinneys. Our international reach extends to renowned chains
                  such as 7/11 and Harrods, underscoring our global presence and
                  trust.​
                </p>
              </div>
              <br />
              <div className="__pvt_icon_section">
                <h2>FMCG Collaborations​</h2>
                <br />
                <div className="__icon_logos">
                  <img src={require("../../img/l1.webp")} />
                  <img src={require("../../img/l2.webp")} />
                  <img src={require("../../img/l4.webp")} />
                  <img src={require("../../img/l9.webp")} />
                  <img src={require("../../img/l5.webp")} />
                  <img src={require("../../img/l6.webp")} />
                  <img src={require("../../img/l8.webp")} />
                  <img src={require("../../img/l7.webp")} />
                  <img src={require("../../img/l3.webp")} />
                  <img src={require("../../img/l11.webp")} />
                  <img src={require("../../img/l17.webp")} />
                </div>
                <p>
                  Our adaptability and quality have attracted partnerships with
                  global FMCG giants, including Galaxy by Mars, Fade Fit, and
                  others. These collaborations reflect our capacity to align
                  with varied brand identities and market demands.​
                </p>
              </div>
            </div>

            <div className="__pvt_icon_section">
              <h2>Entertainment Sector Clients​</h2>
              <br />
              <div className="__icon_logos">
                <img src={require("../../img/l19.webp")} />
                <img src={require("../../img/l20.webp")} />
                <img src={require("../../img/l25.webp")} />
                <img src={require("../../img/l26.webp")} />
                <img src={require("../../img/l21.webp")} />
                <img src={require("../../img/l22.webp")} />
                <img src={require("../../img/l23.webp")} />
                <img src={require("../../img/l24.webp")} />
                <img src={require("../../img/l27.webp")} />
                <img src={require("../../img/l28.webp")} />
                <img src={require("../../img/l30.webp")} />
                <img src={require("../../img/l29.webp")} />
              </div>
              <p>
                In the entertainment arena, we've proudly associated with
                industry leaders such as Majid al Futtaim, Emaar for Burj
                Khalifa, Atlantis, and The Cheesecake Factory, among others.
                These relationships exemplify our ability to infuse creativity
                and uniqueness into products that resonate with a broad
                audience.​ Through these partnerships, Notions Group has not
                only demonstrated its ability to cater to diverse industries but
                has also cemented its position as a frontrunner in the private
                labeling domain. Our commitment to quality, flexibility, and
                innovation continues to drive our growth, forging new paths and
                strengthening existing bonds​
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivateLabels;
