import React from 'react'
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const LarondaSlidShow = () => {
  return (
    <div>
      <OwlCarousel className="owl-theme" loop margin={10} items={1} nav>
        <div class="item">
          <img src={require("../../img/laronda fm/Factory-01.webp")} />
        </div>
        <div class="item">
          <img src={require("../../img/laronda fm/Factory-02.webp")} />
        </div>
        <div class="item">
          <img src={require("../../img/laronda fm/Factory-03.webp")} />
        </div>
        <div class="item">
          <img src={require("../../img/laronda fm/Factory-04.webp")} />
        </div>
        <div class="item">
          <img src={require("../../img/laronda fm/Factory-05.webp")} />
        </div>
        <div class="item">
          <img src={require("../../img/laronda fm/Factory-19.webp")} />
        </div>
        <div class="item">
          <img src={require("../../img/laronda fm/Factory-20.webp")} />
        </div>
      </OwlCarousel>
    </div>
  );
};

export default LarondaSlidShow;
