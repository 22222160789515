import React from "react";
import './Laronda.scss'
import LarondaSlidShow from "../../components/slidShow/LarondaSlidShow";
import { Link } from "react-router-dom";

const Laronda = () => {
  const scrollTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="laronda_con">
      <div className="__container">
        <div className="__factory__wrap_l">
          <div className="__factory_banner_l">
            <LarondaSlidShow />
          </div>

          <div className="__factory__head_l">
            <div className="__factory__logo">
              <img src={require("../../img/nv_08.webp")} />
            </div>
            <div className="__factory__text">
              <p>
                a Dubai based chocolate factory specialized in producing and
                distributing FMCG & gourmet Chocodate, chocolate &amp; date
                brands using the latest state-of-the-art technology all under
                internationally accredited hygienic standards.a Dubai based
                chocolate factory specialized in producing and distributing FMCG
                & gourmet Chocodate, chocolate &amp; date brands using the
                latest state-of-the-art technology all under internationally
                accredited hygienic standards.a Dubai based chocolate factory
                specialized in producing and distributing FMCG & gourmet
                Chocodate, chocolate &amp; date brands using the latest
                state-of-the-art technology all under internationally accredited
                hygienic standards.
              </p>
              <Link to="/factories">Back</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Laronda;
