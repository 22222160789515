import React, { useState } from 'react'
import "./ConfectioneryBrand.scss";

const ConfectioneryBreandHead = () => {
    const scrollTop = () => {
      window.scrollTo(0, 0);
    };
const [isShowMore, setIsShowMore] = useState(false);
      const [isShowMoreTwo, setIsShowMoreTwo] = useState(false);
      const [isShowMoreThree, setIsShowMoreThree] = useState(false);

      const toggleReadMoreLess = () => {
        setIsShowMore(!isShowMore);
      };

      const toggleReadMoreLess_two = () => {
        setIsShowMoreTwo(!isShowMoreTwo);
      };
      const toggleReadMoreLess_Three = () => {
        setIsShowMoreThree(!isShowMoreThree);
      };
  return (
    <div>
      <div className="__container">
        <div className="conf_head_br"   >
          <h1>Confectionery</h1>
          <h2>Specialization in Chocodate</h2>
          <p style={{padding:"0px 10dvw"}} >
            Our pride and joy, Chocodate, embodies the perfect fusion of rich
            chocolate and succulent dates. Created with meticulous care and
            creativity, this guilt-free treat has become a global favorite,
            symbolizing a harmonious blend of traditional flavors and modern
            tastes.
          </p>
        </div>
      </div>
      <br /><br /><br />
      <div className="__container">
        <div className="confBrand">
          <div className="confSectionLeft">
            <div className="conf_section">
              <h2>Global Presence​</h2>
              <p>
                Our strategic location in Dubai allows us to distribute our
                products seamlessly across the world. Notions is proud to have
                such a global reach that it distributes in all five continents.
                ​
              </p>
              <img src={require("../../img/world.webp")} />
            </div>
            <div className="conf_section">
              <h2>Internationally Accredited Hygienic Standards​ ​</h2>
              <p>Our pride and joy, Chocodate,</p>
              {isShowMoreTwo && (
                <p>
                  embodies the perfect fusion of rich chocolate and succulent
                  dates. Created with meticulous care and creativity, this
                  guilt-free treat has become a global favorite, symbolizing a
                  harmonious blend of traditional flavors and modern tastes. ​ ​
                  ​ ​
                </p>
              )}
              <button onClick={toggleReadMoreLess_two}>
                {isShowMoreTwo ? "Read Less" : "Read More"}
              </button>
            </div>
          </div>

          <div className="confSectionCenter">
            <img src={require("../../img/chocolate.webp")} />
          </div>

          <div className="confSectionRight">
            <div className="conf_section">
              <h2>Cutting-Edge Technology​</h2>
              <p>
                At Notions Manufacturing, we embrace the latest technological
                advancements
              </p>
              {isShowMoreThree && (
                <p>
                  to ensure precision, efficiency, and consistency in our
                  production process.​ Notions boasts a production Facility
                  spanning over (rajshekar) SQFT which houses 5 High Speed
                  production lines for a 9 Different categories of confectionary
                  and multiple flavours for each. We have the capacity to
                  produce 3600 Metric tons of confectionary including dates,
                  chocolates, flour and spreads annually etc.
                </p>
              )}
              <button onClick={toggleReadMoreLess_Three}>
                {isShowMoreThree ? "Read Less" : "Read More"}
              </button>
              <img src={require("../../img/iso.webp")} />
            </div>

            <div className="conf_section">
              <h2>Sustainable Practices​</h2>
              <p>in alignment with our mission to contribute positively</p>
              {isShowMore && (
                <p>
                  to our community and environment, Notions is certified for
                  Fair Trade in Cocoa making sure that farmers and traders are
                  paid fairly. We incorporate sustainable practices throughout
                  our production process. Majority of our energy has been made
                  sustainable by incorporating solar energy
                </p>
              )}
              <button onClick={toggleReadMoreLess}>
                {isShowMore ? "Read Less" : "Read More"}
              </button>
              <img src={require("../../img/fire.webp")} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfectioneryBreandHead
