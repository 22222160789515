import React, { useState } from "react";
import back_img_about from "../../img/back_img1.jpeg";
import "./SnacksBrand.scss";
import { Link } from "react-router-dom";
import SnackBrandHead from "./SnackBrandHead";

const SnackBrand = () => {



  const scrollTop =()=>{
    window.scrollTo(0,0)
  }



  const [isShowMoreA, setIsShowMoreA] = useState(false);
  const [isShowMoreB, setIsShowMoreB] = useState(false);
  const [isShowMoreC, setIsShowMoreC] = useState(false);
  const [isShowMoreD, setIsShowMoreD] = useState(false);
  const [isShowMoreE, setIsShowMoreE] = useState(false);
  const [isShowMoreF, setIsShowMoreF] = useState(false);
  const [isShowMoreG, setIsShowMoreG] = useState(false);

  const toggleReadMoreA = () => {
    setIsShowMoreA(!isShowMoreA);
  };
  const toggleReadMoreB = () => {
    setIsShowMoreB(!isShowMoreB);
  };
  const toggleReadMoreC = () => {
    setIsShowMoreC(!isShowMoreC);
  };
  const toggleReadMoreD = () => {
    setIsShowMoreD(!isShowMoreD);
  };
  const toggleReadMoreE = () => {
    setIsShowMoreE(!isShowMoreE);
  };
  const toggleReadMoreF = () => {
    setIsShowMoreF(!isShowMoreF);
  };
  const toggleReadMoreG = () => {
    setIsShowMoreG(!isShowMoreG);
  };

  return (
    <div className="confectioneryBrand">
      

      <div className="__brand_wrp" style={{ backgroundColor: "#f9f9f9" }}>
        <div className="__container">
          <div className="__brand">
            <div className="__brand_img">
              <img src={require("../../img/xl_ban.webp")} />
            </div>
            <div className="__brand_text">
              <img
                style={{ paddingBottom: "30px" }}
                src={require("../../img/xl.webp")}
              />
              <h3>
                Unleash the Extra in Every Bite. Our brand is the embodiment of
                living life to its absolute fullest, with a crunch that's louder
                than the rest
              </h3>
              {isShowMoreE && (
                <p>
                  {" "}
                  flavors that explode over the top and a size that screams
                  extreme. For those who believe in seizing life with both hands
                  and breaking norms, our chips are not just an extra-large
                  snack, but a statement of an extra-intense lifestyle. <br />
                  <br />
                  With XL, you're not just eating, you're making a statement. Go
                  beyond the ordinary, live with extreme gusto - Choose XL!
                  <br />
                  <br />​ XL chips, deliciously refined in flavor with perfect
                  crunch made with freshly sourced potatoes.
                </p>
              )}
              <button onClick={toggleReadMoreE}>
                {isShowMoreE ? "Read Less" : "Read More"}
              </button>
              <Link onClick={scrollTop} to="/sub_snack_brands" className="btn">
                Explore More
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="__brand_wrp">
        <div className="__container">
          <div className="__brand">
            <div className="__brand_text">
              <img
                style={{ paddingBottom: "30px" }}
                src={require("../../img/amigo.webp")}
              />
              <h3>
                Welcome Amigo The Flavor Fiesta You've Been Waiting For Who says
                you need to travel to Mexico to experience the authentic taste
                of corn tortillas?
              </h3>
              {isShowMoreF && (
                <p>
                  Say "Hola" to Amigo, the newest member of our snacking
                  portfolio that transports your tastebuds straight to a Mexican
                  fiesta! Combining the natural goodness of corn with the hearty
                  crunch of potatoes, our Amigo tortillas are an exotic
                  adventure in every bite.
                </p>
              )}
              <button onClick={toggleReadMoreF}>
                {isShowMoreF ? "Read Less" : "Read More"}
              </button>
            </div>

            <div className="__brand_img">
              <img src={require("../../img/amigo_bn.webp")} />
            </div>
          </div>
          <div className="__brand_info">
            <div>
              <img
                style={{ paddingBottom: "30px" }}
                src={require("../../img/am_1.webp")}
              />
              <h2>Sweet Chilli</h2>
            </div>

            <div>
              <img
                style={{ paddingBottom: "30px" }}
                src={require("../../img/am_4.webp")}
              />
              <h2>Nacho Cheese</h2>
            </div>

            <div>
              <img
                style={{ paddingBottom: "30px" }}
                src={require("../../img/am_3.webp")}
              />
              <h2>Salted</h2>
            </div>

            <div>
              <img
                style={{ paddingBottom: "30px" }}
                src={require("../../img/am_5.webp")}
              />
              <h2>Chilli</h2>
            </div>

            <div>
              <img
                style={{ paddingBottom: "30px" }}
                src={require("../../img/am_2.webp")}
              />
              <h2>Cheese</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="__brand_wrp" style={{ backgroundColor: "#f9f9f9" }}>
        <div className="__container">
          <div className="__brand">
            <div className="__brand_img">
              <img src={require("../../img/raja_ban.webp")} />
            </div>
            <div className="__brand_text">
              <img
                style={{ paddingBottom: "30px" }}
                src={require("../../img/raja_logo.webp")}
              />
              <h3>
                A Taste of Tradition, A Crunch of Home For over three decades
                Raja Potato Crunchies have held a special place in the hearts{" "}
              </h3>

              {isShowMoreG && (
                <p>
                  and pantries of families across the UAE and KSA. With a legacy
                  that transcends generations, our Salad Chips are more than
                  just a snack; they're a cultural cornerstone that evokes fond
                  memories and nostalgia for those who've grown up munching on
                  them.
                </p>
              )}
              <button onClick={toggleReadMoreG}>
                {isShowMoreG ? "Read Less" : "Read More"}
              </button>
            </div>
          </div>
          <div className="__brand_info_raja">
            <div>
              <img
                style={{ paddingBottom: "30px" }}
                src={require("../../img/raja_3.webp")}
              />
              <h2>Sour Cream</h2>
            </div>

            <div>
              <img
                style={{ paddingBottom: "30px" }}
                src={require("../../img/raja_2.webp")}
              />
              <h2>Salted</h2>
            </div>

            <div>
              <img
                style={{ paddingBottom: "30px" }}
                src={require("../../img/raja_1.webp")}
              />
              <h2>Salted</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SnackBrand;
