import React from "react";
import "./TimeLine.css";
import LocalFloristIcon from "@mui/icons-material/LocalFlorist";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import LuggageIcon from "@mui/icons-material/Luggage";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import AddReactionIcon from "@mui/icons-material/AddReaction";

const TimeLine = () => {
  return (
    <div className="__timeline">
      <div className="__container">
        <div className="__time_head">
          <h1>
            Our Journey <span>Tradition and Modernity combined</span> ​
          </h1>
        </div>

        <div className="time_line" id="line">

          <div class="branch flip">
            <div class="content left">
              <LocalFloristIcon className="__icon" />
              <h2 class="year">1991</h2>
              <h3>journey started </h3>
              <p class="text">
                The journey started in 1991 when our CEO, Fawaz Masri founded La
                Ronda Chocolate factory. ​
              </p>
            </div>
          </div>

          <div class="branch mirror-down turn">
            <div class="content right">
              <LooksOneIcon className="__icon" />
              <h2 class="year">1992</h2>
              <h3>1st ever Chocodate</h3>
              <p class="text">
                A unique delicacy was invented for the very first time by
                inserting an Almond inside a date and enrobing with belgian
                chocolate by Mr Fawaz Masri himself ​
              </p>
            </div>
          </div>
          
          <div class="branch flip">
            <div class="content left">
              <LuggageIcon className="__icon" />
              <h2 class="year">1993</h2>
              <h3>Dubai Duty Free</h3>
              <p class="text">
                Chocodate became an instant success and was recognised by
                tourists from around the world as a part of Dubai itself ​
              </p>
            </div>
          </div>
          <div class="branch mirror-down turn">
            <div class="content right">
              <FlightTakeoffIcon className="__icon" />
              <h2 class="year">1992</h2>
              <h3>Export</h3>
              <p class="text">
                The demand for chocodate grew with the flow of tourists.
                Starting with 1 country at a time, Notions grew its presence to
                50+ countries​
              </p>
            </div>
          </div>
          <div class="branch flip">
            <div class="content left">
              <AddReactionIcon className="__icon" />
              <h2 class="year">1992</h2>
              <h3>The confectionary and snacks expert</h3>
              <p class="text">
                Notions Group with its experience over the years stands tall as
                an expert in Confectionary and Snacks with the capability to
                produce multiple categories.​
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeLine;
