import React, { useState } from 'react'
import "./subconfbrand.scss";

const Subconfbrand = () => {
     const [isShowMore_A, setIsShowMore_A] = useState(false);
     const [isShowMore_B, setIsShowMore_B] = useState(false);
     const [isShowMore_C, setIsShowMore_C] = useState(false);
     const [isShowMore_D, setIsShowMore_D] = useState(false);
     const [isShowMore_E, setIsShowMore_E] = useState(false);
     const [isShowMore_F, setIsShowMore_F] = useState(false);
     const [isShowMore_G, setIsShowMore_G] = useState(false);
     const [isShowMore_H, setIsShowMore_H] = useState(false);
     const [isShowMore_I, setIsShowMore_I] = useState(false);
     const [isShowMore_J, setIsShowMore_J] = useState(false);
     const [isShowMore_K, setIsShowMore_K] = useState(false);
     const [isShowMore_L, setIsShowMore_L] = useState(false);
     const [isShowMore_M, setIsShowMore_M] = useState(false);
     const [isShowMore_N, setIsShowMore_N] = useState(false);
     const [isShowMore_O, setIsShowMore_O] = useState(false);
     const [isShowMore_P, setIsShowMore_P] = useState(false);
     const [isShowMore_Q, setIsShowMore_Q] = useState(false);
     const [isShowMore_R, setIsShowMore_R] = useState(false);
     const [isShowMore_S, setIsShowMore_S] = useState(false);
     const [isShowMore_T, setIsShowMore_T] = useState(false);
     const [isShowMore_U, setIsShowMore_U] = useState(false);
     const [isShowMore_V, setIsShowMore_V] = useState(false);
     const [isShowMore_W, setIsShowMore_W] = useState(false);
     const [isShowMore_X, setIsShowMore_X] = useState(false);
     const [isShowMore_Y, setIsShowMore_Y] = useState(false);
     const [isShowMore_Z, setIsShowMore_Z] = useState(false);
     const [isShowMore_AA, setIsShowMore_AA] = useState(false);
     const [isShowMore_AB, setIsShowMore_AB] = useState(false);
     const [isShowMore_AC, setIsShowMore_AC] = useState(false);

     const toggleReadMoreLess_A = () => {
       setIsShowMore_A(!isShowMore_A);
     };
     const toggleReadMoreLess_B = () => {
       setIsShowMore_B(!isShowMore_B);
     };
     const toggleReadMoreLess_C = () => {
       setIsShowMore_C(!isShowMore_C);
     };
     const toggleReadMoreLess_D = () => {
       setIsShowMore_D(!isShowMore_D);
     };
     const toggleReadMoreLess_E = () => {
       setIsShowMore_E(!isShowMore_E);
     };
     const toggleReadMoreLess_F = () => {
       setIsShowMore_F(!isShowMore_F);
     };
     const toggleReadMoreLess_G = () => {
       setIsShowMore_G(!isShowMore_G);
     };
     const toggleReadMoreLess_H = () => {
       setIsShowMore_H(!isShowMore_H);
     };
     const toggleReadMoreLess_I = () => {
       setIsShowMore_I(!isShowMore_I);
     };
     const toggleReadMoreLess_J = () => {
       setIsShowMore_J(!isShowMore_J);
     };
     const toggleReadMoreLess_K = () => {
       setIsShowMore_K(!isShowMore_K);
     };
     const toggleReadMoreLess_L = () => {
       setIsShowMore_L(!isShowMore_L);
     };
     const toggleReadMoreLess_M = () => {
       setIsShowMore_M(!isShowMore_M);
     };
     const toggleReadMoreLess_N = () => {
       setIsShowMore_N(!isShowMore_N);
     };
     const toggleReadMoreLess_O = () => {
       setIsShowMore_O(!isShowMore_O);
     };
     const toggleReadMoreLess_P = () => {
       setIsShowMore_P(!isShowMore_P);
     };
     const toggleReadMoreLess_Q = () => {
       setIsShowMore_Q(!isShowMore_Q);
     };
     const toggleReadMoreLess_R = () => {
       setIsShowMore_R(!isShowMore_R);
     };
     const toggleReadMoreLess_S = () => {
       setIsShowMore_S(!isShowMore_S);
     };
     const toggleReadMoreLess_T = () => {
       setIsShowMore_T(!isShowMore_T);
     };
     const toggleReadMoreLess_U = () => {
       setIsShowMore_U(!isShowMore_U);
     };
     const toggleReadMoreLess_V = () => {
       setIsShowMore_V(!isShowMore_V);
     };

     const toggleReadMoreLess_W = () => {
       setIsShowMore_W(!isShowMore_W);
     };

     const toggleReadMoreLess_X = () => {
       setIsShowMore_X(!isShowMore_X);
     };

     const toggleReadMoreLess_Y = () => {
       setIsShowMore_Y(!isShowMore_Y);
     };

     const toggleReadMoreLess_Z = () => {
       setIsShowMore_Z(!isShowMore_Z);
     };

     const toggleReadMoreLess_AA = () => {
       setIsShowMore_AA(!isShowMore_AA);
     };

     const toggleReadMoreLess_AB = () => {
       setIsShowMore_AB(!isShowMore_AB);
     };

     const toggleReadMoreLess_AC = () => {
       setIsShowMore_AC(!isShowMore_AC);
     };
  return (
    <div className="subconfbrand">
      <div className="sub_confectionery">
        <div className="__container">
          <div className="__sub__con_head">
            <img src={require("../../img/choco.webp")} />
            <br />
            <h2>
              CHOCODATE® is an exquisite bite sized delicacy made with 3 simple
              ingredients including rich silky Chocolate
            </h2>
          </div>
        </div>

        <div style={{ backgroundColor: "#f9f9f9", width: "100vw" }}>
          <div className="sub__conf_wrap_one __container" id="1">
            <div className="sub__conf_img">
              <img src={require("../../img/chocodate.webp")} />
            </div>
            <div className="sub__conf_txt">
              <div className="sub__conf_text_head">
                <h1>CHOCODATE® ​</h1>
                <h3>
                  covering a velvety Arabian Date and filled with a golden
                  roasted Almond After 31 years of rich heritage
                </h3>
              </div>

              {isShowMore_B && (
                <p>
                  CHOCODATE® from a hidden gem homegrown in Dubai to become one
                  of the most loved brands with over 500,000 chocodates consumed
                  every day across 70 countries. ​ A hand-made treat crafted
                  with passion and love, using the highest quality ingredients,
                  since its creation 31 years ago under its genuine brand
                  CHOCODATE®.
                  <br />
                  <br />​ CHOCODATE® combines the heritage and modern cultures
                  creating a guilt free indulgence pleasing to every palate
                  across the globe
                </p>
              )}
              <button onClick={toggleReadMoreLess_B}>
                {isShowMore_B ? "Read Less" : "Read More"}
              </button>
            </div>
          </div>

          <div className="__sub__con_main_cat __container">
            <div className="__sub__con_cat_card">
              <div className="__sub__con_cat_img">
                <img src={require("../../img/ch02.webp")} />
              </div>
              <div className="__sub__con__cat_text">
                <h2>Dark​</h2>​
              </div>
            </div>

            <div className="__sub__con_cat_card">
              <div className="__sub__con_cat_img">
                <img src={require("../../img/ch03.webp")} />
              </div>
              <div className="__sub__con__cat_text">
                <h2>Caramel​</h2>​
              </div>
            </div>

            <div className="__sub__con_cat_card">
              <div className="__sub__con_cat_img">
                <img src={require("../../img/ch04.webp")} />
              </div>
              <div className="__sub__con__cat_text">
                <h2>85%​​</h2>​
              </div>
            </div>

            <div className="__sub__con_cat_card">
              <div className="__sub__con_cat_img">
                <img src={require("../../img/ch05.webp")} />
              </div>
              <div className="__sub__con__cat_text">
                <h2>No added sugar​</h2>​
              </div>
            </div>

            <div className="__sub__con_cat_card">
              <div className="__sub__con_cat_img">
                <img src={require("../../img/ch06.webp")} />
              </div>
              <div className="__sub__con__cat_text">
                <h2>Milk​</h2>​
              </div>
            </div>

            <div className="__sub__con_cat_card">
              <div className="__sub__con_cat_img">
                <img src={require("../../img/ch07.webp")} />
              </div>
              <div className="__sub__con__cat_text">
                <h2>Ruby​</h2>​
              </div>
            </div>

            <div className="__sub__con_cat_card">
              <div className="__sub__con_cat_img">
                <img src={require("../../img/ch08.webp")} />
              </div>
              <div className="__sub__con__cat_text">
                <h2>Coconut​</h2>​
              </div>
            </div>

            <div className="__sub__con_cat_card">
              <div className="__sub__con_cat_img">
                <img src={require("../../img/ch09.webp")} />
              </div>
              <div className="__sub__con__cat_text">
                <h2>White​</h2>​
              </div>
            </div>
          </div>
        </div>

        <div className=" sub_wrap  __container">
          <div className="sub__conf_wrap" id="2">
            <div className="sub__conf_txt">
              <div className="sub__conf_text_head">
                <h1>CHOCODATE Organic​</h1>
                <h2>
                  Chocodate is thrilled to introduce our Organic Range,
                  featuring three exquisite flavors that cater to diverse
                  palates
                </h2>
              </div>
              <p>
                Milk Chocolate, White Chocolate, and Dark Chocolate. True to the
                organic ethos, this range is designed to offer a guilt-free
                indulgence by eliminating additives and artificial
                preservatives, thus staying committed to providing clean,
                wholesome ingredients.
                <br />
                <br />
              </p>
              {isShowMore_C && (
                <span>
                  <p>
                    {" "}
                    Ethical Sourcing and Production: At Chocodate, we believe
                    that good taste shouldn't come at the expense of ethical
                    responsibility. That's why our Organic Range is sourced and
                    produced through methods that respect the environment and
                    local producers. We maintain transparent, fair trade
                    relationships with our suppliers, ensuring that each
                    delicious Chocodate treat is a product of ethical practices
                    from farm to table.
                    <br />
                    <br />
                    Non-GMO Commitment: We understand that our discerning
                    customers prefer natural, unaltered ingredients, which is
                    why our Organic Range is 100% non-GMO. You can indulge in
                    our chocolate delights, knowing that you're consuming
                    products as close to their natural state as possible.
                  </p>
                </span>
              )}
              <button onClick={toggleReadMoreLess_C}>
                {isShowMore_C ? "Read Less" : "Read More"}
              </button>
            </div>

            <div className="sub__conf_img">
              <img src={require("../../img/chocodate1.webp")} />
            </div>
          </div>
          <img className="icons" src={require("../../img/icons.jpg")} />

          <div className="sub__sub__text">
            <h2>Flavorful Adventures Await</h2>
            <div className="sub_sub_in_wrap">
              <div className="sub_sub_in_wrap_sec">
                <img src={require("../../img/03og.webp")} />
                <h3>Dark</h3>
              </div>
              <div className="sub_sub_in_wrap_sec">
                <img src={require("../../img/02og.webp")} />
                <h3>Milk</h3>
              </div>
              <div className="sub_sub_in_wrap_sec">
                <img src={require("../../img/01og.webp")} />
                <h3>White</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Subconfbrand
