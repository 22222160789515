import React, { useState } from "react";
import "./SnacksBrand.scss";

const SnackBrandHead = () => {
  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  const [isShowMoreA, setIsShowMoreA] = useState(false);
  const [isShowMoreB, setIsShowMoreB] = useState(false);
  const [isShowMoreC, setIsShowMoreC] = useState(false);
  const [isShowMoreD, setIsShowMoreD] = useState(false);

  const toggleReadMoreA = () => {
    setIsShowMoreA(!isShowMoreA);
  };
  const toggleReadMoreB = () => {
    setIsShowMoreB(!isShowMoreB);
  };
  const toggleReadMoreC = () => {
    setIsShowMoreC(!isShowMoreC);
  };
  const toggleReadMoreD = () => {
    setIsShowMoreD(!isShowMoreD);
  };

  return (
    <div>
      <div className="__container">
        <div className="conf_head">
          <h1>Snacking</h1>
          <h2>
            Introducing Star Foods Industries Where Excellence Meets Production
          </h2>
          <p style={{ padding: "0px 10dvw" }}>
            At the heart of snacking perfection lies Star Foods Industries, a
            pinnacle of dedication, precision, and unparalleled quality. With
            the astounding capacity to produce over 15,200 tons of potato chips
            and snacks annually
          </p>
          {isShowMoreA && (
            <p style={{padding:"0px 10dvw"}} >
              {" "}
              we've firmly established our reputation in the global snacks
              industry. Integral to our operations is our team. An experienced
              assembly of 280 professionals, their expertise drives our
              processes and ensures that every product that leaves our facility
              is a testament to our commitment to excellence. Through continuous
              training and dedication, they're not just staff; they're the heart
              of Star Foods Industries.
            </p>
          )}
          <button onClick={toggleReadMoreA}>
            {isShowMoreA ? "Read Less" : "Read More"}
          </button>
        </div>
      </div>
      <div className="__container">
        <div className="confBrand">
          <div className="confSectionLeft">
            <div>
              <h2>A World of Variety​</h2>
              <p>
                Our culinary prowess doesn't just stop at volume; it's about
                variety too. We proudly craft 16+ distinct varieties, ensuring
                that every palate finds its delight within our extensive range.
                From classic favorites to innovative flavors, our offerings are
                as diverse as they are delectable.
              </p>
              <img src={require("../../img/world.webp")} />
            </div>
            <div>
              <h2>Internationally Accredited Hygienic Standards​ ​</h2>
              <p>
                Our commitment to excellence extends beyond taste and
                presentation
              </p>
              {isShowMoreB && (
                <p>
                  We adhere to internationally recognized hygiene standards,
                  ensuring that every product that leaves our factory is both
                  safe and delightful. We are approved by highest quality
                  standard awarding bodies globally including. HACCP, ISO 9001,
                  and Halal​
                </p>
              )}
              <button onClick={toggleReadMoreB}>
                {isShowMoreB ? "Read Less" : "Read More"}
              </button>
            </div>
          </div>

          <div className="confSectionCenter">
            <img src={require("../../img/snacks.webp")} />
          </div>

          <div className="confSectionRight">
            <div>
              <h2>State-of-the-Art Facilities​</h2>
              <p>
                Our production magic takes place in a sprawling facility spread
                over 22,000 square meters.
              </p>
              {isShowMoreC && (
                <p>
                  Within these walls, we've incorporated 5 fully automated
                  production lines, designed with cutting-edge technology. This
                  enables us to maintain the highest standards of quality,
                  consistency, and hygiene with each product that carries the
                  Star Foods name
                </p>
              )}
              <button onClick={toggleReadMoreC}>
                {isShowMoreC ? "Read Less" : "Read More"}
              </button>
            </div>
            <div>
              <img src={require("../../img/lf.png")} />
              <h2>Local Fresh Procurement​</h2>
              <p>
                An integral part of our procurement process involves the direct
                sourcing of our main raw material
              </p>

              {isShowMoreD && (
                <p>
                  which is none other than potatoes. As we are situated in the
                  fertile region of Hail, one of the largest areas for potato
                  cultivation, we directly engage with local fields to procure
                  this vital ingredient.
                </p>
              )}
              <button onClick={toggleReadMoreD}>
                {isShowMoreD ? "Read Less" : "Read More"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SnackBrandHead;
