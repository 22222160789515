import React, { useState } from 'react'
import back_img_about from "../../img/back_img1.jpeg";
import "./ConfectioneryBrand.scss"
import { Link } from 'react-router-dom';
import ConfectioneryBreandHead from './ConfectioneryBreandHead';


const ConfectioneryBrand = () => {
  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  const [isShowMore, setIsShowMore] = useState(false);

  const [isShowMoreFour, setIsShowMoreFour] = useState(false);
  const [isShowMoreA, setIsShowMoreA] = useState(false)
    const [isShowMoreB, setIsShowMoreB] = useState(false);
        const [isShowMoreC, setIsShowMoreC] = useState(false);

  const toggleReadMoreLess = () => {
    setIsShowMore(!isShowMore);
  };
  
   const toggleReadMoreLess_Four = () => {
     setIsShowMoreFour(!isShowMoreFour);
   };
    const toggleReadMoreA = () => {
      setIsShowMoreA(!isShowMoreA);
    };

      const toggleReadMoreB = () => {
        setIsShowMoreB(!isShowMoreB);
      };

         const toggleReadMoreC = () => {
           setIsShowMoreC(!isShowMoreC);
         };



  return (
    <div className="confectioneryBrand">

      <div className="__container">
        <div className="__brand">
          <div className="__brand_img I_a">
            <img src={require("../../img/choco_d.webp")} />
          </div>
          <div className="__brand_text I_b">
            <img
              style={{ paddingBottom: "30px" }}
              src={require("../../img/choco.webp")}
            />
            <h3>
              CHOCODATE® is an exquisite bite sized delicacy made with 3 simple
              ingredients including rich silky Chocolate <br />
            </h3>
            {isShowMoreFour && (
              <p>
                covering a velvety Arabian Date and filled with a golden roasted
                Almond After 31 years of rich heritage CHOCODATE® from a hidden
                gem homegrown in Dubai to become one of the most loved brands
                with over 500,000 chocodates consumed every day across 70
                countries. ​ A hand-made treat crafted with passion and love,
                using the highest quality ingredients, since its creation 25
                years ago under its genuine brand CHOCODATE®.
                <br />
                <br />​ CHOCODATE® combines the heritage and modern cultures
                creating a guilt free indulgence pleasing to every palate across
                the globe
              </p>
            )}
            <button onClick={toggleReadMoreLess_Four}>
              {isShowMoreFour ? "Read Less" : "Read More"}
            </button>
            <div className="brand_icons">
              <div>
                <img src={require("../../img/icon_01.webp")} />
                <p>Industry Pioneer​</p>
              </div>
              <div>
                <img src={require("../../img/icon_02.webp")} />
                <p>95% Weighted Distribution in UAE​</p>
              </div>
              <div>
                <img src={require("../../img/icon_03.webp")} />
                <p>Market Leader​ 20% Share​</p>
              </div>
              <div>
                <img src={require("../../img/icon_04.webp")} />
                <p>Registered Trade Mark​</p>
              </div>
            </div>
            <Link onClick={scrollTop} to="/chocodate" className="btn">
              Explore More
            </Link>
          </div>
        </div>

        <div className="__brand">
          <div className="__brand_text I_c">
            <img
              style={{ paddingBottom: "30px" }}
              src={require("../../img/ADD.webp")}
            />
            <h3>
              Arabian Delights is a collection of delicious treats that
              celebrate cultural exchange from around the world.
            </h3>
            {isShowMoreA && (
              <p>
                ​ They are not only an iconic example of Arabian hospitality but
                also a tasty healthy snack that reflect the perfect merger of
                East and West while offering a guilt-free indulgence pleasing to
                every plate across the globe marking one of the unique
                manifestations of our beautifully interconnected world.
              </p>
            )}
            <button onClick={toggleReadMoreA}>
              {isShowMoreA ? "Read Less" : "Read More"}
            </button>
            <Link onClick={scrollTop} to="/arabian_delights" className="btn">
              Explore More
            </Link>
          </div>

          <div className="__brand_img I_d">
            <img src={require("../../img/arbian_d.webp")} />
          </div>
        </div>

        <div className="__brand">
          <div className="__brand_img I_e">
            <img src={require("../../img/laron_d.webp")} />
          </div>
          <div className="__brand_text I_f">
            <img
              style={{ paddingBottom: "30px" }}
              src={require("../../img/laronda_logo.webp")}
            />
            <h3>
              La Ronda chocolate-covered nuts start with beautifully browned
              crunchy nuts and finished with a smooth
            </h3>
            {isShowMoreB && (
              <p>
                bittersweet chocolate shell. These nuts have an impressive
                gourmet taste and make excellent gifts or party snacks
              </p>
            )}
            <button onClick={toggleReadMoreB}>
              {isShowMoreB ? "Read Less" : "Read More"}
            </button>
          </div>
        </div>

        <div className="__brand">
          <div className="__brand_text I_i">
            <img
              style={{ paddingBottom: "30px" }}
              src={require("../../img/fiesta_logo.webp")}
            />
            <h3>
              Fiesta new range of raw and coated almonds is a collection of
              marvellous treats loaded with irresistible pleasure
            </h3>
            {isShowMoreC && (
              <p>
                Carefully selected almonds that are high in vitamin E, fibers,
                full of antioxidants, nutrients, and all packed in a friendly
                and convenient sizes making these treats the perfect pick-me-up
                snacks for everyday thrills
              </p>
            )}
            <button onClick={toggleReadMoreC}>
              {isShowMoreC ? "Read Less" : "Read More"}
            </button>
          </div>

          <div className="__brand_img I_j">
            <img src={require("../../img/fiesta.webp")} />
          </div>
        </div>

        <div className="__brand">
          <div className="__brand_img I_e">
            <img src={require("../../img/w_pro.webp")} />
          </div>
          <div className="__brand_text I_f">
            <img
              style={{ paddingBottom: "30px" }}
              src={require("../../img/wish_logo.webp")}
            />
            <h3>
              "Wishes" is more than a chocolate collection it's a palette of
              edible emotions, a tapestry of tastes that turns any moment into a
              celebration.
            </h3>
            {isShowMoreB && (
              <p>
                With its bouquet of nine artisanal blends, "Wishes" is a
                culinary symphony designed to serenade every palate. Whether
                it's a soul-warming gift for someone special or the star
                attraction at your next family gathering, this array of
                chocolaty delights is a passport to instant joy
                <br /> <br />
                From the tactile luxury of its packaging to the curated range of
                flavors inside, "Wishes" isn't just chocolate—it's an experience
                waiting to be unwrapped and shared. So why settle for ordinary,
                when you can wish for extraordinary?
              </p>
            )}
            <button onClick={toggleReadMoreB}>
              {isShowMoreB ? "Read Less" : "Read More"}
            </button>
          </div>
        </div>

        <div className="__brand">
          <div className="__brand_text I_i">
            <img
              style={{ paddingBottom: "30px" }}
              src={require("../../img/r_logo.webp")}
            />
            <h3>
              "Rouge" isn't just chocolate; it's an opulent affair that elevates
              the senses and dances with your taste buds. Crafted from the crème
              de la crème
            </h3>
            {isShowMoreC && (
              <p>
                of European gourmet cocoa each piece is a lavish embrace infused
                with sumptuous nuts and luscious fruit fillings. It's not just a
                premium chocolate collection; it's an ode to love, a symphony of
                flavors that immortalizes moments and makes them unforgettable.
                <br />
                <br />
                Perfect for bestowing upon your loved ones, "Rouge" offers an
                experience that's as decadent as it is memorable. A treasure
                chest of two divine forms: the "Clusters of Euphoria," an exotic
                mélange of nuts and fruits, and the "Enrobed Nobles," nuts
                adorned in a chocolate gown. Both masterpieces offer a sublime
                sensory escape that leaves an indelible mark on the canvas of
                your memories.
                <br />
                <br />
                Choose "Rouge," and you're not just gifting chocolate—you're
                wrapping up love, luxury, and lasting memories in one
                extraordinary package.
              </p>
            )}
            <button onClick={toggleReadMoreC}>
              {isShowMoreC ? "Read Less" : "Read More"}
            </button>
          </div>

          <div className="__brand_img I_j">
            <img src={require("../../img/Roude.webp")} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfectioneryBrand
