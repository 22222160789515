import React from 'react'
import ReactPlayer from "react-player";
import './Hero_banner.scss';
import wallpaper from "../../img/banner.jpeg";

const HeroBanner = () => {
  return (
    <>
      <div className="heroBanner ">
        <div
          className="__wall_wrap"
          style={{
            backgroundImage: `url(${wallpaper})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "70vh",
          }}
        ></div>

        <div
          style={{
            backgroundBlendMode: "screen",
          }}
          className="css-selector"
        >
          <h1>Welcome to Notions Group</h1>
        </div>

        {/*<video preload="none" autoplay="autoplay" loop="loop" controls="controls">
        <source src="./Videos/Banner.mp4" type="video/mp4"></source>
  </video>*/}
      </div>
    </>
  );
}

export default HeroBanner
