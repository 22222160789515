import React, { useState } from 'react'
import './DutyFree.scss'
import back_img from "../../img/d_f.webp";
import Form from '../../components/form/Form';

const DutyFree = () => {
  
  return (
    <div className="duty_free">
      <div
        className="__duty_head"
        style={{
          backgroundImage: `url(${back_img})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <h1>Duty-Free</h1>
      </div>
      <div className="__container">
        <div className="__duty_free">
          <div className="__duty_subhead">
            <h1>
              Chocodate & Arabian Delights A Global Journey Through{" "}
              <span>Duty-Free</span>
              Airports​
            </h1>
            <h2>
              The allure of Chocodate and Arabian Delights extends far beyond
              domestic shelves, with a burgeoning presence in over 15+
              international duty-free airports. And that's just the beginning.
              By 2025, we aim to double our reach by marking our delectable
              footprint in more than 30 airports worldwide.
            </h2>
          </div>

          <div className="__duty_f_imgs">
            <img src={require("../../img/dd_f_1.jpeg")} />
            <img src={require("../../img/dd_f_2.jpeg")} />
            <img src={require("../../img/dd_f_3.jpeg")} />
          </div>
        </div>

        <div className="gift_wrap">
          <div className="__container">
            <div className="gift_sec">
              <div className="gift_text">
                <h1>Travel Retail Souvenirs</h1>
                <p>
                  In the dynamic world of consumer engagement, CHOCODATE is
                  focusing on a premium gifting proposition for our consumers.
                  We offer a wide variety of gift boxes that focus on sense
                  place and moments that bring people together. Offer your loved
                  a memorable souvenir and some sensible indulgence
                </p>
              </div>
              <div className="gift_img">
                <img src={require("../../img/gift.webp")} alt="" />
              </div>
            </div>
          </div>
        </div>

        <div className="__container">
          <div className='__con_form'>
            <div className="__duty_text">
              <h2>A Partnership Rooted in History</h2>
              <p>
                Our journey through the duty-free landscape began in 1994, when
                we established a strategic partnership with Dubai Airport.
                Today, it stands as the cornerstone of our global retail
                strategy and serves as our biggest contributing duty-free
                outlet. Through this partnership, travelers from more than 80
                countries get to take a piece of the UAE back home.
              </p>
              <br />

              <h2>Making an Impact</h2>
              <p>
                With 14.36 million tourists passing through Dubai Airport
                annually, our brands have become more than just treats—they're a
                part of the cultural fabric. They serve as cherished souvenirs
                that travelers love to gift or enjoy themselves. We proudly hold
                the title of Arabian Delights being the 2nd highest-selling
                brand in the Confectionery Category at Dubai Airport.
              </p>
              <br />
              <h2>Engaging the Consumer</h2>
              <p>
                To make the shopping experience even more memorable, we run
                multiple consumer-oriented campaigns. Our most exciting
                initiatives include contests like "Win a Trip to Dubai" and "Win
                a Trip to Paris," as well as lucky draws for Gold coins. These
                campaigns keep travelers engaged and coming back for more, as
                they embark on their own journeys across the globe
              </p>
            </div>
            <div>
              <Form />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DutyFree
