import React from 'react'
import "./Notions.scss";
import { Link } from 'react-router-dom';
import NgSlidShow from '../../components/slidShow/NgSlidShow';

const Notions = () => {
  const scrollTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="notions">
      <div className="__container">
        <div className="__factory_wrap">
          <div className="__factory_banner">
            <NgSlidShow />
          </div>
          <div className="__factory__head">
            <div className="__factory__logo">
              <img src={require("../../img/Notions Manufacturing.webp")} />
            </div>
            <div className="__factory__text">
              <p>
                a Dubai based chocolate factory specialized in producing and
                distributing FMCG & gourmet Chocodate, chocolate &amp; date
                brands using the latest state-of-the-art technology all under
                internationally accredited hygienic standards.a Dubai based
                chocolate factory specialized in producing and distributing FMCG
                & gourmet Chocodate, chocolate &amp; date brands using the
                latest state-of-the-art technology all under internationally
                accredited hygienic standards.a Dubai based chocolate factory
                specialized in producing and distributing FMCG & gourmet
                Chocodate, chocolate &amp; date brands using the latest
                state-of-the-art technology all under internationally accredited
                hygienic standards.
              </p>
              <Link to="/factories">Back</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Notions
