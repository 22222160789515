import React from 'react'
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const NgSlidShow = () => {
  return (
    <div>
      <OwlCarousel className="owl-theme" loop margin={10} items={1} nav>
        <div class="item">
          <img src={require("../../img/ng fm/Factory-09.webp")} />
        </div>
        <div class="item">
          <img src={require("../../img/ng fm/Factory-10.webp")} />
        </div>
        <div class="item">
          <img src={require("../../img/ng fm/Factory-11.webp")} />
        </div>
        <div class="item">
          <img src={require("../../img/ng fm/Factory-12.webp")} />
        </div>
        <div class="item">
          <img src={require("../../img/ng fm/Factory-13.webp")} />
        </div>
        <div class="item">
          <img src={require("../../img/ng fm/Factory-14.webp")} />
        </div>
      </OwlCarousel>
    </div>
  );
}

export default NgSlidShow
