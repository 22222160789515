import React, { useState } from "react";
import "./Factories.scss";
import qut_img from "../../img/qut.webp";
import back_img from "../../img/factories.png";
import wear_img from "../../img/ware_img.webp";
import GroupsRoundedIcon from "@mui/icons-material/GroupsRounded";
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import DomainAddRoundedIcon from "@mui/icons-material/DomainAddRounded";
import { Link } from "react-router-dom";
import { FaCircleInfo } from "react-icons/fa6";

const Factories = () => {
   const scrollTop = () => {
     window.scrollTo(0, 0);
   };

  const [isShowMoreA, setIsShowMoreA] = useState(false);
  const [isShowMoreB, setIsShowMoreB] = useState(false);
const [isShowMoreC, setIsShowMoreC] = useState(false);
const [isShowMoreD, setIsShowMoreD] = useState(false);
const [isShowMoreE, setIsShowMoreE] = useState(false);
const [isShowMoreF, setIsShowMoreF] = useState(false);
const [isShowMoreG, setIsShowMoreG] = useState(false);

   const toggleReadMoreLessA = () => {
     setIsShowMoreA(!isShowMoreA);
   };
   const toggleReadMoreLessB = () => {
     setIsShowMoreB(!isShowMoreB);
   };
    const toggleReadMoreLessC = () => {
      setIsShowMoreC(!isShowMoreC);
    };

     const toggleReadMoreLessD = () => {
       setIsShowMoreD(!isShowMoreD);
     };

      const toggleReadMoreLessE = () => {
        setIsShowMoreE(!isShowMoreE);
      };

 const toggleReadMoreLessF = () => {
   setIsShowMoreF(!isShowMoreF);
 };

  const toggleReadMoreLessG = () => {
    setIsShowMoreG(!isShowMoreG);
  };



  return (
    <div className="__laronda">
      <div
        className="__laronda__fac__wrap"
        style={{
          backgroundImage: `url(${back_img})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="__container">
          <div className="__fac__head">
            <h1>FACTORIES</h1>
          </div>
        </div>
      </div>
      <div className="__factory_all">
        <div className="__container">
          <div className="factory_list">
            <Link to="/laronda">
              <div className="factory_card" onClick={scrollTop()}>
                <div className="factory_img">
                  <img src={require("../../img/nv_08.webp")} />
                </div>
                <br />
                <h3>Chocodate Confectionery</h3>
                <br />
                <FaCircleInfo color="black" />
              </div>
            </Link>
            <Link to="/notions">
              <div className="factory_card" onClick={scrollTop()}>
                <div className="factory_img">
                  <img src={require("../../img/Notions Manufacturing.webp")} />
                </div>
                <br />
                <h3>Malban & Gourmet Chocolate</h3>
                <br />
                <FaCircleInfo color="black" />
              </div>
            </Link>

            <Link to="/starfood">
              <div className="factory_card" onClick={scrollTop()}>
                <div className="factory_img">
                  <img src={require("../../img/nv_09.webp")} />
                </div>
                <br />
                <h3>Salty Snacks</h3>
                <br />
                <FaCircleInfo color="black" />
              </div>
            </Link>
          </div>
        </div>
      </div>

      <div
        style={{
          backgroundImage: `url(${qut_img})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="__container">
          <div className="__fac__qut">
            <div className="__qut_text">
              <h1>
                Quality <span>Assurance</span>​
              </h1>
              <p>
                Notions' extensive list of certifications showcases its
                unwavering dedication to excellence in every aspect of
                production. These certifications act as seals of approval,
                affirming the company's commitment to international standards of
                quality, safety, ethical considerations, and legal compliance.
                <br />
                <br />
                Customers can enjoy Notions' products with full confidence,
                knowing that each certification represents a layer of trust,
                integrity, and care. Additionally, these certifications help
                Notions stand out in the competitive global marketplace,
                facilitating access to diverse markets, enhancing brand
                reputation, and solidifying relationships with partners,
                distributors, and consumers.​
              </p>
            </div>
            <div className="__qut_img"></div>
          </div>
        </div>
      </div>

      <div className="__container">
        <div className="__certificate_wrap">
          <h1>Certifications</h1>
          <div className="__certifi">
            <div className="__certi__card">
              <div className="__certi_img">
                <img src={require("../../img/c_01.webp")} />
              </div>
              <div className="__certi__text">
                <p>
                  HACCP Certified (Hazard Analysis and Critical Control Points)
                </p>
                {isShowMoreA && (
                  <p>
                    By identifying and controlling potential hazards at specific
                    points in the production process, Notions ensures to
                    minimize risks, maintain high food quality, and protect
                    consumer health
                  </p>
                )}
                <button onClick={toggleReadMoreLessA}>
                  {isShowMoreA ? "Read Less" : "Read More"}
                </button>
              </div>
            </div>

            <div className="__certi__card">
              <div className="__certi_img">
                <img src={require("../../img/c_03.webp")} />
              </div>
              <div className="__certi__text">
                <p>Halal Certification​ By complying with Halal</p>
                {isShowMoreB && (
                  <p>
                    standards, Notions ensures that its products meet the
                    dietary requirements of the Muslim community.
                  </p>
                )}
                <button onClick={toggleReadMoreLessB}>
                  {isShowMoreB ? "Read Less" : "Read More"}
                </button>
              </div>
            </div>

            <div className="__certi__card">
              <div className="__certi_img">
                <img src={require("../../img/c_04.webp")} />
              </div>
              <div className="__certi__text">
                <p>
                  ISO 9001 (Quality Management System)​ ISO 9001 certification
                </p>
                {isShowMoreC && (
                  <p>
                    indicates that Notions has implemented a quality management
                    system that emphasizes continuous improvement, customer
                    satisfaction, and adherence to globally recognized quality
                    standards.
                  </p>
                )}
                <button onClick={toggleReadMoreLessC}>
                  {isShowMoreC ? "Read Less" : "Read More"}
                </button>
              </div>
            </div>

            <div className="__certi__card">
              <div className="__certi_img">
                <img src={require("../../img/c_06.webp")} />
              </div>
              <div className="__certi__text">
                <p>Gluten-Free Certification​ </p>
                {isShowMoreD && (
                  <p>
                    ensures that Notions' products are suitable for individuals
                    with gluten intolerance or celiac disease.
                  </p>
                )}
                <button onClick={toggleReadMoreLessD}>
                  {isShowMoreD ? "Read Less" : "Read More"}
                </button>
              </div>
            </div>
            <div className="__certi__card">
              <div className="__certi_img">
                <img src={require("../../img/c_02.webp")} />
              </div>
              <div className="__certi__text">
                <p>IFS International Featured Standards​ IFS certification</p>
                {isShowMoreE && (
                  <p>
                    is a testament to Notions' commitment to quality and safety
                    in food production. It sets rigorous standards for quality
                    management, food safety, and compliance with regulations,
                    enhancing consumer trust and providing a competitive edge in
                    international markets.
                  </p>
                )}
                <button onClick={toggleReadMoreLessE}>
                  {isShowMoreE ? "Read Less" : "Read More"}
                </button>
              </div>
            </div>
            <div className="__certi__card">
              <div className="__certi_img">
                <img src={require("../../img/c_05.webp")} />
              </div>
              <div className="__certi__text">
                <p>
                  FDA Approved U.S. Food and Drug Administration​ FDA approval
                </p>
                {isShowMoreF && (
                  <p>
                    signifies that Notions meets the stringent regulations set
                    by the U.S. government for food products. This allows the
                    company to export its products to the United States, one of
                    the world's largest consumer markets
                  </p>
                )}
                <button onClick={toggleReadMoreLessF}>
                  {isShowMoreF ? "Read Less" : "Read More"}
                </button>
              </div>
            </div>

            <div className="__certi__card">
              <div className="__certi_img">
                <img src={require("../../img/c_07.webp")} />
              </div>
              <div className="__certi__text">
                <p>Organic Certificate​ By obtaining an Organic Certificate,</p>
                {isShowMoreG && (
                  <p>
                    Notions verifies that its products are produced without the
                    use of synthetic chemicals, GMOs, or other artificial
                    interventions.
                  </p>
                )}
                <button className="read_btn" onClick={toggleReadMoreLessG}>
                  {isShowMoreG ? "Read Less" : "Read More"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="__warehousing"
        style={{
          backgroundImage: `url(${wear_img})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          padding: "40px 0px",
        }}
      >
        <div className="__container">
          <div className="__warehousing_info">
            <div className="__werehousing_head">
              <h1>Warehousing​</h1>
            </div>

            <div className="__warehousing_list">
              <div className="__uae">
                <h1>UAE</h1>
                <p>
                  <span>Total Size:</span> The total warehousing space is 10,800
                  square feet, offering ample room for inventory management and
                  operations.
                  <br />
                  <br />
                  <span>Locations:</span> Situated in DIP, this location likely
                  offers strategic advantages in terms of connectivity and
                  accessibility to key markets and transportation hubs.
                  <br />
                  <br />
                  <span>Total Capacity:</span> With the ability to accommodate
                  1944 pallets, NOTIONS Group can handle a significant volume of
                  goods. This speaks to the company's ability to cater to
                  substantial demand and ensures that storage needs are
                  effectively met.
                  <br />
                  <br />
                  <span>Temperature Controlled:</span> This feature is vital for
                  preserving the quality of temperature-sensitive products.
                  Whether for food items, pharmaceuticals, or other specialized
                  goods, temperature control ensures that products are
                  maintained in optimal conditions.​ Dedicated Warehousing
                  <br />
                  <br />
                  <span>Staff:</span> Having 23 dedicated warehousing staff
                  members demonstrates the company's commitment to efficient
                  warehousing operations. These specialists likely handle tasks
                  ranging from inventory management to order fulfillment,
                  working cohesively to maintain smooth workflows
                </p>
              </div>
              <div className="__ksa">
                <h1>KSA</h1>
                <p>
                  <span>Total Size:</span> Spanning a total area of 26,530
                  square feet, these warehousing facilities are substantial and
                  demonstrate the company's commitment to logistical operations
                  in the region. <br />
                  <br /> ​ <span>Total Capacity:</span> With the ability to
                  accommodate 875+ pallets across all warehouses, NOTIONS
                  Group's storage capability in KSA ensures that they can handle
                  a wide range of goods and support various business needs.​ br
                  <br />
                  <br />
                  <span>Locations: </span>The six warehouses are strategically
                  located in Dammam, Hail, Madina, Riyadh, Jeddah, and Abha.
                  These locations cover the major regions of KSA, allowing for
                  efficient distribution and access to key markets.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="__human_re">
        <div className="__container">
          <div className="__human_head">
            <h1>
              Human <span>Resource</span>
            </h1>
            <p>
              Embracing a wide variety of cultural backgrounds not only promotes
              inclusivity but also brings a diversity of perspectives and ideas.
              This fosters a richer and more adaptable environment, enhancing
              problem-solving and creative thinking.
            </p>
          </div>
          <div className="__human_text">
            <div className="__human_text_div_top">
              <div className="__item_s">
                <GroupsRoundedIcon
                  style={{
                    fontSize: "50px",
                    color: "gray",
                    paddingBottom: "10px",
                  }}
                />
                <br />
                <h2>Total Employees</h2>
                <p>
                  With a workforce of 222 employees, Notions Global is able to
                  cover a wide range of expertise and functions.​
                </p>
              </div>
              <div className="__item_s">
                <DashboardRoundedIcon
                  style={{
                    fontSize: "50px",
                    color: "gray",
                    paddingBottom: "10px",
                  }}
                />
                <br />
                <h2>15 Department</h2>
                <p>
                  Operating across 5 different companies implies a multifaceted
                  approach and possibly a wide geographical reach. It suggests a
                  diverse portfolio and expertise in various markets or
                  industries. From special chocodate, chocolate and snacks
                  production to owning a wide distribution network across UAE
                  and KSA. ​ ​
                </p>
              </div>
              <div className="__item_s">
                <DomainAddRoundedIcon
                  style={{
                    fontSize: "50px",
                    color: "gray",
                    paddingBottom: "10px",
                  }}
                />
                <br />
                <h2>5 Companies</h2>
                <p>
                  The structure is compartmentalized into 15 departments,
                  allowing specialization and focused efficiency within various
                  aspects of the business including marketing, sales, finance,
                  R&D, Production etc ​
                </p>
              </div>
            </div>

            <br />
            <br />
            <div className="__human_text_div">
              <h3>Diversity and Inclusion. ​</h3>
              <br />

              <p>
                Notions is strong believer of diversity. Being born and bred in
                the UAE, the group has always embraced the ethnic melting pot
                that Dubai has always been. In Notions, we have more than 20
                Nationalities working together for a united goal. Each and every
                person brings a new flavor and experience to the table, which
                helps innovating new products and ideas.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Factories;
