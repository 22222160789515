import React, { useState } from 'react'
import './Baking.scss'
import baking_img from "../../img/almond.jpg";

const Baking = () => {  
  const [isShowMoreA, setIsShowMoreA] = useState(false);
  const [isShowMoreB, setIsShowMoreB] = useState(false);
  const [isShowMoreC, setIsShowMoreC] = useState(false);
  const [isShowMoreD, setIsShowMoreD] = useState(false);

  const toggleReadMoreLessA = () => {
    setIsShowMoreA(!isShowMoreA);
  };
  const toggleReadMoreLessB = () => {
    setIsShowMoreB(!isShowMoreB);
  };
  const toggleReadMoreLessC = () => {
    setIsShowMoreC(!isShowMoreC);
  };
  const toggleReadMoreLessD = () => {
    setIsShowMoreD(!isShowMoreD);
  };

  return (
    <div className="confectionery_bki">
      <div className="__container">
        <div className="__con_head">
          <h1>Baking</h1>
          <p>
            The ever expanding multiverse of Notions Confectionary now includes​
          </p>
        </div>

        <div className="__con_main_cat_bk">
          <a href="#5">
            <div className="__con_cat_card_bk">
              <div className="__con_cat_img">
                <img src={require("../../img/butter.webp")} />
              </div>
              <div className="__con__cat_text">
                <h2>Nut Butter</h2>​
              </div>
            </div>
          </a>

          <a href="#6">
            <div className="__con_cat_card_bk">
              <div className="__con_cat_img">
                <img src={require("../../img/coconaut.webp")} />
              </div>
              <div className="__con__cat_text">
                <h2>Coconut Sugar​​</h2>​
              </div>
            </div>
          </a>

          <a href="#7">
            <div className="__con_cat_card_bk">
              <div className="__con_cat_img">
                <img src={require("../../img/con_07.webp")} />
              </div>
              <div className="__con__cat_text">
                <h2>Almond flour ​</h2>​
              </div>
            </div>
          </a>

          <a href="#8">
            <div className="__con_cat_card_bk">
              <div className="__con_cat_img">
                <img src={require("../../img/con_08.webp")} />
              </div>
              <div className="__con__cat_text">
                <h2>Baking chocolate​​</h2>​
              </div>
            </div>
          </a>
        </div>
      </div>

      <div style={{ backgroundColor: "#f9f9f9" }}>
        <div className="conf_wrap  __container" id="5">
          <div className="conf_img I_a">
            <img src={require("../../img/p_butter.webp")} />
          </div>
          <div className="conf_txt I_b">
            <div className="conf_text_head">
              <h1>Nut Butter</h1>
            </div>
            <h3>
              Nut butters will spread a whole new delicious If you’re a peanut
              butter nutter, we’ve got great news for you: Your world is about
              to explode with new flavours and ideas – big time.
            </h3>
            {isShowMoreA && (
              <p>
                We’ve blended up four different types of nuts, pairing a few of
                them with a selection of other nuts and things for a delicious
                line of nut butters that will made you salivate at the
                possibilities. Spread them on bread, add them to recipes or just
                dip a spoon into a jar, and savour the flavour of purity and
                wholesome goodness
              </p>
            )}
            <button onClick={toggleReadMoreLessA}>
              {isShowMoreA ? "Read Less" : "Read More"}
            </button>
          </div>
        </div>
      </div>

      <div>
        <div className="conf_wrap  __container" id="6">
          <div className="conf_txt I_c">
            <div className="conf_text_head">
              <h1>Coconut Sugar</h1>
              <h3>
                Use it for baking, in your coffee, on your cereal and anywhere
                else you add sugar.It’s tropical goodness is retained thanks
              </h3>
            </div>

            {isShowMoreB && (
              <p>
                to the way in which we preserve the healthy coconutty goodness
                from tree to table. It’s every bit as easy to bake and cook with
                and has a fantastic flavour and sweetness level on par with
                table sugar, but it’s got it all over that white stuff when it
                comes to health benefits. Use our coconut sugar, lose the table
                sugar – and life just gets sweeter.
              </p>
            )}
            <button onClick={toggleReadMoreLessB}>
              {isShowMoreB ? "Read Less" : "Read More"}
            </button>
          </div>
          <div className="conf_img I_d">
            <img src={require("../../img/suger.webp")} />
          </div>
        </div>
      </div>

      <div style={{ backgroundColor: "#f9f9f9" }}>
        <div className="conf_wrap __container" id="7">
          <div className="conf_img I_e">
            <img src={require("../../img/baking.webp")} />
          </div>
          <div className="conf_txt I_e">
            <div className="conf_text_head">
              <h1>Baking flours</h1>
            </div>
            <h3>
              almond flour will convince you – even if you’re a
              died-in-the-wool,old school wheat flour aficionado. You use the
              same preparation procedures
            </h3>
            {isShowMoreC && (
              <p>
                get the same fluffy and consistent breads, pastries and cakes,
                but with a ton of added health and taste benefits. Sleep easily
                knowing you’ve served your family something healthier, realer
                and without the hyper-refined mysteries of conventional wheat
                flour.
              </p>
            )}
            <button onClick={toggleReadMoreLessC}>
              {isShowMoreC ? "Read Less" : "Read More"}
            </button>
          </div>
        </div>
      </div>

      <div>
        <div className="conf_wrap  __container" id="8">
          <div className="conf_txt I_i">
            <div className="conf_text_head">
              <h1>Baking chocolate</h1>
              <h3>
                {" "}
                Made it cleaner We have taken the old classic choco chip, made
                it cleaner, healthier and tastier
              </h3>
            </div>
            {isShowMoreD && (
              <p>
                Then, not content to rest on our laurels, we went a little
                bonkers and added a bunch of variations on the theme, creating a
                whole new line of superb chocolate chips for baking, snacking or
                sneaking into foods and dishes they’ve not been seen in before.
                We leave that part up to your baker’s brain. BTW, these little
                flavour buddies are all-natural, gluten-free, vegetarian and
                preservative-free.
              </p>
            )}
            <button onClick={toggleReadMoreLessD}>
              {isShowMoreD ? "Read Less" : "Read More"}
            </button>
          </div>
          <div className="conf_img I_j">
            <img src={require("../../img/chips.webp")} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Baking
