import React, { useState } from 'react'
import './Starfood.scss'
import { Link } from 'react-router-dom';
import StarFoodSlidShow from '../../components/slidShow/StarFoodSlidShow';

const Starfood = () => {
  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  const [isShowMoreA, setIsShowMoreA] = useState(false);

  const toggleReadMoreA = () => {
    setIsShowMoreA(!isShowMoreA);
  };

  return (
    <div className="star__food">
      <div className="__container">
        <div className="__factory_wrap_s">
          <div className="__factory_banner_s">
            <StarFoodSlidShow />
          </div>
          <div className="__factory__head_s">
            <div className="__factory__logo">
              <img src={require("../../img/Star Food Logo.png")} />
            </div>
            <div className="__factory__text">
              <p>
                Star Food Industries, a proud member of the esteemed Notions
                Group, stands as a beacon of excellence in the realm of snack
                manufacturing in Saudi Arabia. Specializing in a diverse array
                of power-packed products such as chips, potato sticks, puffs,
                and tortillas, Star Food Industries has earned a reputation for
                delivering irresistible flavors and quality snacks under its
                flagship brands like XL, Amigo, Raja, Fiesta, Twister, Twinkle,
                and many more. <br />
                {isShowMoreA && (
                  <p>
                    Product Portfolio: Star Food Industries' commitment to
                    culinary innovation is reflected in its extensive product
                    portfolio. From the crunchy satisfaction of XL chips to the
                    playful delight of Twinkle puffs, the company caters to a
                    wide range of taste preferences. Whether it's the
                    traditional charm of Raja potato sticks or the zest of
                    Fiesta tortillas, Star Food Industries ensures that each
                    product carries the essence of quality and freshness.
                    <br /> Manufacturing Excellence: Operating from the heart of
                    Saudi Arabia, Star Food Industries boasts state-of-the-art
                    manufacturing facilities that adhere to the highest
                    international standards. The company's commitment to quality
                    control, hygiene, and innovation is evident throughout the
                    production process. Utilizing advanced technology and
                    employing skilled professionals, Star Food Industries
                    ensures that every snack leaving its facilities meets the
                    expectations of discerning consumers. Iconic Brands: The
                    array of iconic brands produced by Star Food Industries
                    reflects the company's dedication to offering diverse and
                    appealing snack options. XL, with its bold and intense
                    flavors, caters to those seeking a robust snacking
                    experience. Amigo, on the other hand, appeals to the
                    fun-loving spirit with its playful range of snacks. Raja,
                    Fiesta, Twister, Twinkle, and others each bring a unique
                    character to the snack aisle, allowing consumers to explore
                    a variety of taste sensations. <br /> <br /> Private Labeling
                    Partnerships: In addition to its stellar lineup of branded
                    products, Star Food Industries has established strong
                    partnerships in private labeling. Collaborating with
                    renowned retailers such as Carrefour, LULU, Fade Fit, and
                    Ferico, the company extends its commitment to quality and
                    innovation to a broader audience. Through these
                    collaborations, Star Food Industries contributes to the
                    success of its partners by delivering snacks that reflect
                    their unique brand identities. Sustainable Practices: Beyond
                    flavor and quality, Star Food Industries places a strong
                    emphasis on sustainable and responsible business practices.
                    From sourcing raw materials ethically to implementing
                    eco-friendly packaging solutions, the company is dedicated
                    to minimizing its environmental footprint. This commitment
                    reflects the organization's holistic approach to corporate
                    responsibility. <br /><br />  Star Food Industries, a key
                    player in the Notions Group, stands as a pioneer in the
                    snack manufacturing industry in Saudi Arabia. With a rich
                    array of products, iconic brands, and strategic partnerships
                    in private labeling, the company continues to be a
                    trailblazer in delivering delightful snacking experiences to
                    consumers across the region. As the culinary journey
                    unfolds, Star Food Industries remains committed to
                    excellence, innovation, and the satisfaction of snack
                    enthusiasts
                  </p>
                )}
                <button onClick={toggleReadMoreA}>
                  {isShowMoreA ? "Read Less" : "Read More"}
                </button>
              </p>
              <Link to="/factories">Back</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Starfood
