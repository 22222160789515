import React, { useState } from 'react'

const Arabian = () => {
  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

      const [isShowMore_A, setIsShowMore_A] = useState(false);
      const [isShowMore_B, setIsShowMore_B] = useState(false);
      const [isShowMore_C, setIsShowMore_C] = useState(false);
      const [isShowMore_D, setIsShowMore_D] = useState(false);
      const [isShowMore_E, setIsShowMore_E] = useState(false);
      const [isShowMore_F, setIsShowMore_F] = useState(false);
      const [isShowMore_G, setIsShowMore_G] = useState(false);
      const [isShowMore_H, setIsShowMore_H] = useState(false);
      const [isShowMore_I, setIsShowMore_I] = useState(false);
      const [isShowMore_J, setIsShowMore_J] = useState(false);
      const [isShowMore_K, setIsShowMore_K] = useState(false);
      const [isShowMore_L, setIsShowMore_L] = useState(false);
      const [isShowMore_M, setIsShowMore_M] = useState(false);
      const [isShowMore_N, setIsShowMore_N] = useState(false);
      const [isShowMore_O, setIsShowMore_O] = useState(false);
      const [isShowMore_P, setIsShowMore_P] = useState(false);
      const [isShowMore_Q, setIsShowMore_Q] = useState(false);
      const [isShowMore_R, setIsShowMore_R] = useState(false);
      const [isShowMore_S, setIsShowMore_S] = useState(false);
      const [isShowMore_T, setIsShowMore_T] = useState(false);
      const [isShowMore_U, setIsShowMore_U] = useState(false);
      const [isShowMore_V, setIsShowMore_V] = useState(false);
      const [isShowMore_W, setIsShowMore_W] = useState(false);
      const [isShowMore_X, setIsShowMore_X] = useState(false);
      const [isShowMore_Y, setIsShowMore_Y] = useState(false);
      const [isShowMore_Z, setIsShowMore_Z] = useState(false);
      const [isShowMore_AA, setIsShowMore_AA] = useState(false);
      const [isShowMore_AB, setIsShowMore_AB] = useState(false);
      const [isShowMore_AC, setIsShowMore_AC] = useState(false);

      const toggleReadMoreLess_A = () => {
        setIsShowMore_A(!isShowMore_A);
      };
      const toggleReadMoreLess_B = () => {
        setIsShowMore_B(!isShowMore_B);
      };
      const toggleReadMoreLess_C = () => {
        setIsShowMore_C(!isShowMore_C);
      };
      const toggleReadMoreLess_D = () => {
        setIsShowMore_D(!isShowMore_D);
      };
      const toggleReadMoreLess_E = () => {
        setIsShowMore_E(!isShowMore_E);
      };
      const toggleReadMoreLess_F = () => {
        setIsShowMore_F(!isShowMore_F);
      };
      const toggleReadMoreLess_G = () => {
        setIsShowMore_G(!isShowMore_G);
      };
      const toggleReadMoreLess_H = () => {
        setIsShowMore_H(!isShowMore_H);
      };
      const toggleReadMoreLess_I = () => {
        setIsShowMore_I(!isShowMore_I);
      };
      const toggleReadMoreLess_J = () => {
        setIsShowMore_J(!isShowMore_J);
      };
      const toggleReadMoreLess_K = () => {
        setIsShowMore_K(!isShowMore_K);
      };
      const toggleReadMoreLess_L = () => {
        setIsShowMore_L(!isShowMore_L);
      };
      const toggleReadMoreLess_M = () => {
        setIsShowMore_M(!isShowMore_M);
      };
      const toggleReadMoreLess_N = () => {
        setIsShowMore_N(!isShowMore_N);
      };
      const toggleReadMoreLess_O = () => {
        setIsShowMore_O(!isShowMore_O);
      };
      const toggleReadMoreLess_P = () => {
        setIsShowMore_P(!isShowMore_P);
      };
      const toggleReadMoreLess_Q = () => {
        setIsShowMore_Q(!isShowMore_Q);
      };
      const toggleReadMoreLess_R = () => {
        setIsShowMore_R(!isShowMore_R);
      };
      const toggleReadMoreLess_S = () => {
        setIsShowMore_S(!isShowMore_S);
      };
      const toggleReadMoreLess_T = () => {
        setIsShowMore_T(!isShowMore_T);
      };
      const toggleReadMoreLess_U = () => {
        setIsShowMore_U(!isShowMore_U);
      };
      const toggleReadMoreLess_V = () => {
        setIsShowMore_V(!isShowMore_V);
      };

      const toggleReadMoreLess_W = () => {
        setIsShowMore_W(!isShowMore_W);
      };

      const toggleReadMoreLess_X = () => {
        setIsShowMore_X(!isShowMore_X);
      };

      const toggleReadMoreLess_Y = () => {
        setIsShowMore_Y(!isShowMore_Y);
      };

      const toggleReadMoreLess_Z = () => {
        setIsShowMore_Z(!isShowMore_Z);
      };

      const toggleReadMoreLess_AA = () => {
        setIsShowMore_AA(!isShowMore_AA);
      };

      const toggleReadMoreLess_AB = () => {
        setIsShowMore_AB(!isShowMore_AB);
      };

      const toggleReadMoreLess_AC = () => {
        setIsShowMore_AC(!isShowMore_AC);
      };



    return (
      <div className="subconfbrand">
        <div className="sub_confectionery">
          <div className="__container">
            <div className="__sub__con_head">
              <img src={require("../../img/ADD.webp")} />
              <br />
              <p>
                Arabian Delights is proud to present a delectable array of
                fruits and dates, skillfully crafted to satisfy both traditional
                and modern culinary desires. Our collection offers the perfect
                blend of classic Arabian flavors with a contemporary twist,
                ensuring that each bite takes you on a delightful journey of
                taste and texture
              </p>
              {isShowMore_F && (
                <p>
                  Khordary Dates: Revered for their natural sweetness and chewy
                  texture, our Khordary dates are a tribute to time-honored
                  Arabian traditions. Harvested at the peak of ripeness, these
                  dates are available in both plain and nut-filled options. For
                  those who seek an extra layer of complexity, we offer Khordary
                  dates filled with high-quality nuts such as almonds, walnuts,
                  and pistachios. Each nut-stuffed date is a mini-feast of
                  contrasting flavors and textures, providing a sumptuous treat
                  that tantalizes the palate.
                </p>
              )}

              <button onClick={toggleReadMoreLess_F}>
                {isShowMore_F ? "Read Less" : "Read More"}
              </button>
            </div>
          </div>

          <div style={{ backgroundColor: "#f9f9f9", width: "100vw" }}>
            <div className="sub__conf_wrap_one __container" id="1">
              <div className="sub__conf_img">
                <img src={require("../../img/arabian.webp")} />
              </div>
              <div className="sub__conf_txt">
                <div className="sub__conf_text_head">
                  <h1>Arabian Delights​​</h1>
                  <h3>
                    Arabian Delights is a collection of delicious treats that
                    celebrate cultural exchange from around the world.
                  </h3>
                </div>

                {isShowMore_A && (
                  <p>
                    They are not only an iconic example of Arabian hospitality
                    but also a tasty healthy snack that reflect the perfect
                    merger of East and West while offering a guilt-free
                    indulgence pleasing to every plate across the globe marking
                    one of the unique manifestations of our beautifully
                    interconnected world.
                  </p>
                )}
                <button onClick={toggleReadMoreLess_A}>
                  {isShowMore_A ? "Read Less" : "Read More"}
                </button>
              </div>
            </div>
          </div>

          <div className=" sub_wrap  __container">
            <div className="sub__conf_wrap" id="2">
              <div className="sub__conf_txt">
                <div className="sub__conf_text_head">
                  <h1>Chocolate-Coated Fruits​</h1>
                  <h2>
                    Arabian Delights' fruits and dates are not just snacks; they
                    are an experience that celebrates the richness of Arabian
                    heritage​
                  </h2>
                </div>
                <p>
                  while embracing modern culinary innovation. Perfect for
                  gifting, special occasions, or simply for personal indulgence,
                  our selection guarantees a high-quality, flavorful journey
                  that honors both the past and the present.
                </p>
                {isShowMore_B && (
                  <span>
                    <p>
                      Figs: The naturally sweet and juicy essence of figs is
                      wonderfully complemented by a coating of luxurious
                      chocolate. Whether you opt for milk, white, or dark
                      chocolate, each variant offers a unique interplay of
                      flavors that elevates the humble fig to a gourmet
                      experience.
                    </p>
                    <br />


                    <p>
                      Apricots: Our chocolate-coated apricots provide an
                      exceptional contrast of tart and sweet, further enriched
                      by the addition of high-quality chocolate. The milk
                      chocolate version offers a creamy, comforting taste, while
                      the white chocolate variant provides a lighter, velvety
                      touch. For those who prefer a deeper cocoa flavor, the
                      dark chocolate-coated apricots are sure to hit the mark.
                    </p>
                  </span>
                )}
                <button onClick={toggleReadMoreLess_B}>
                  {isShowMore_B ? "Read Less" : "Read More"}
                </button>
              </div>

              <div className="sub__conf_img">
                <img src={require("../../img/ad_date.webp")} />
              </div>
            </div>
          </div>

          <div style={{ backgroundColor: "#f9f9f9", width: "100vw" }}>
            <div className="sub__conf_wrap_one __container" id="1">
              <div className="sub__conf_img">
                <img src={require("../../img/ab_malban.webp")} />
              </div>
              <div className="sub__conf_txt">
                <div className="sub__conf_text_head">
                  <h1>Arabian Delights​ Malban​</h1>
                  <h3>
                    Arabian Delights Malban are hand made bites with high
                    quality nuts in various shapes & combinations, offering you
                    a wider and irresistible assortments.
                  </h3>
                </div>

                {isShowMore_D && (
                  <p>
                    Our Malban range reflects the authentic Arabic sweet
                    delicacies all made with grape molasses, condensed with
                    starch making them a delicious treats to satisfy your every
                    craving.
                    <br />
                    <br />
                    Rose water and roasted pistachios are added to enrich the
                    flavor and to add the special crunchy texture to our soft
                    chewy Malban for an exceptional experience.
                    <br />
                    <br />
                    The combination of different hand selected nuts and fruits
                    creates many savory varieties all individually wrapped to
                    ensure the highest level of freshness.
                    <br />
                    <br />
                    Each Bite is loaded with its exquisite taste of sweet
                    delicacy or candy with rose water aroma, and filled with
                    nuts. The perfect pick-me up for everyday thrills.
                  </p>
                )}
                <button onClick={toggleReadMoreLess_D}>
                  {isShowMore_D ? "Read Less" : "Read More"}
                </button>
              </div>
            </div>
          </div>

          <div className=" sub_wrap  __container">
            <div className="sub__conf_wrap" id="2">
              <div className="sub__conf_txt">
                <div className="sub__conf_text_head">
                  <h1>Arabian Delights​ Croquant</h1>
                  <h3>
                    Arabian Delights rolls are made from the finest selection of
                    premium rolls filled with rich & smooth chocolate filling on
                    the inside, giving you melt in mouth indulgence experience
                    and covered with quality nuts on the outside​
                  </h3>
                </div>

                {isShowMore_E && (
                  <span>
                    <p>
                      This irresistible fusion of taste and texture is a in
                      between meal, fill me up sweet snack that will boost you
                      with energy & indulgence.
                    </p>

                    <p>
                      You can enjoy Arabian Delights chocolate rolls in 5 unique
                      flavors: Oreo, Lotus, Hazelnut, Almond, and coffee.
                    </p>
                  </span>
                )}
                <button onClick={toggleReadMoreLess_E}>
                  {isShowMore_E ? "Read Less" : "Read More"}
                </button>
              </div>

              <div className="sub__conf_img">
                <img src={require("../../img/ab_cr.webp")} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );




}

export default Arabian
