import React from 'react'
import './Footer.scss'
import { Link } from 'react-router-dom';

const Footer = () => {

  const scrillTop =()=>{
    window.scroll(0,0)
  }
  return (
    <div className=" __footer">
      <div className="__container">
        <div className="__footWrap">
          <div className="__footSection">
            <img src={require("../../img/w-logo.png")} />
            <p>
              Passion is the force that drives us; reason is what steers our
              endeavours. We put our hearts and our minds in every little detail
              of the business to achieve the best results. We are{" "}
            </p>
            <li >
              <Link style={{color:"orange" , fontSize:"12px"}}  to="/index.php/catalog">Download Catalog</Link>
            </li>
          </div>
          <div className="__footSection_two">
            <ul>
              <h2 style={{ color: "white" }}>Quick links</h2>
              <li>
                <Link to="/about">
                  <button className="f_btn" onClick={scrillTop}>
                    About
                  </button>
                </Link>
              </li>
              <li>
                <Link to="/confectionery">
                  <button className="f_btn" onClick={scrillTop}>
                    Category
                  </button>
                </Link>
              </li>
              <li>
                <Link to="/snacks">
                  <button className="f_btn" onClick={scrillTop}>
                    Snacks
                  </button>
                </Link>
              </li>
              <li>
                <Link to="/baking">
                  <button className="f_btn" onClick={scrillTop}>
                    Baking
                  </button>
                </Link>
              </li>
              <li>
                <Link to="/malban">
                  <button className="f_btn" onClick={scrillTop}>
                    Malban
                  </button>
                </Link>
              </li>
              <li>
                <Link to="/confectionery">
                  <button className="f_btn" onClick={scrillTop}>
                    Confectionery
                  </button>
                </Link>
              </li>
              <li>
                <Link to="/confectionery_brands">
                  <button className="f_btn" onClick={scrillTop}>
                    Brand Confectionery
                  </button>
                </Link>
              </li>
              <li>
                <Link to="/snacks_brand">
                  <button className="f_btn" onClick={scrillTop}>
                    Brand Snacks
                  </button>
                </Link>
              </li>
            </ul>
            <ul>
              <li>
                <Link to="/factories">
                  <button className="f_btn" onClick={scrillTop}>
                    Factories
                  </button>
                </Link>
              </li>
              <li>
                <Link to="/notions">
                  <button className="f_btn" onClick={scrillTop}>
                    Notions Group
                  </button>
                </Link>
              </li>
              <li>
                <Link to="/laronda">
                  <button className="f_btn" onClick={scrillTop}>
                    Laronda Factory
                  </button>
                </Link>
              </li>
              <li>
                <Link to="/starfood">
                  <button className="f_btn" onClick={scrillTop}>
                    Starfood Factory
                  </button>
                </Link>
              </li>
              <li>
                <Link to="/private_labels">
                  <button className="f_btn" onClick={scrillTop}>
                    Private Labels
                  </button>
                </Link>
              </li>

              <li>
                <Link to="/export">
                  <button className="f_btn" onClick={scrillTop}>
                    Export
                  </button>
                </Link>
              </li>
              <li>
                <Link to="/dutyfree">
                  <button className="f_btn" onClick={scrillTop}>
                    Duty-Free
                  </button>
                </Link>
              </li>
              <li>
                <Link to="/retails">
                  <button className="f_btn" onClick={scrillTop}>
                    Distribution Partners
                  </button>
                </Link>
              </li>
              <li>
                <Link to="/career">
                  <button className="f_btn" onClick={scrillTop}>
                    Career
                  </button>
                </Link>
              </li>
              <li>
                <Link to="/contact">
                  <button className="f_btn" onClick={scrillTop}>
                    Contact
                  </button>
                </Link>
              </li>
            </ul>
          </div>
          <div className="__footSection">
            <div className="__f_contact">
              <h1>UAE</h1>
              <p>La Ronda Emirates LLC</p>
              <p>
                Dubai Investment Park 2 <br /> P.O. Box 37782 Dubai
              </p>
            </div>
            <div className="__f_contact">
              <h1>Saudi Arabia</h1>
              <p>Starfood Industries LLC</p>
              <p>
                Hail Industrial City, <br /> King Abdulaziz Rd, Hail 55414
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="__copy_right">
        <div className="__container">
          <div className="__copy_text">
            <p>Notions Group. Copyright © 2023</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer
