import React from "react";
import "./Home.scss";
import ReactPlayer from "react-player";
import OurBrand from "../../components/ourBrands/OurBrand";
import back_img from "../../img/back_img.png";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import HeroBanner from "../../components/hero/Hero_banner";
import Retails from "../../components/retails/Retails";
import Partners from "../../components/partners/Partners";
import { Link } from "react-router-dom";
import WorldMap from "../../components/world_map/WorldMap";





const Home = () => {

  const scrollTop = ()=>{
    window.scrollTo (0,0)
  }



  return (
    <div className="__home ">
      {/* <div className="ex_banner">
        <img src={require("../../img/NG Gulfood Sig - 02.jpg")} />
      </div> */}
      <HeroBanner />
      <div className="__heading __container">
        <h1>
          Our brands combine superior <span>Notions</span> of the world <br />{" "}
          so do the principles we live by.
        </h1>
        <br />
        <p>
          For a model that has worked so well with many of our products, we use
          the <br /> same equation to form our corporate values.
        </p>
      </div>

      <div className="__ceo __container">
        <div className="__video">
          <ReactPlayer
            className="__player"
            url={"Videos/about.mp4"}
            controls={true}
            loop={true}
            width="auto"
            height="100%"
            autoPlay={true}
            playing={true}
          />
        </div>
        <div className="__ceoText_wrap">
          <div className="__ceoText ">
            <img src={require("../../img/ceo.png")} />
            <p>
              I’ve been passionately working in the food industry since the
              1960s starting out with a humble role in my father’s specialty
              food store in Amman. Throughout my career, I’ve specialized in
              various facets of the food industry ranging from trading to food
              service to manufacturing.
            </p>
            <span>Fawaz Masri</span>
            <small>Founder & CEO</small>
          </div>
        </div>
      </div>
      <div className="__global">
        <div className="__container ">
          <div className="__global_text">
            <div className="__global_head">
              <h1>
                Global <span>Markets</span> <br />
                50+ Countries
              </h1>
            </div>
            <div></div>
            <div className="__global_sub_text">
              <h2>
                Notions Global growing strong every year by adding a new regions
                and countries. Currently, the{" "}
                <span style={{ color: "#fcb315" }}>
                  Arabian Delights and Chocodate
                </span>{" "}
                is loved by people across 5 continents​
              </h2>
            </div>
          </div>
          <div className="__global_map">
            {/* <img src={require("../../img/map.webp")} />*/}
            <WorldMap />
          </div>
        </div>
      </div>
      <OurBrand />
      <div
        className="__about_home "
        style={{
          backgroundImage: `url(${back_img})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="__container">
          <div className="__about_home_text ">
            <h1>QUALITY RESULTS WITH US</h1>
            <p>
              Passion is the force that drives us; reason is what steers our
              endeavours. We put our hearts and our minds in every little detail
              of the business to achieve the best results. We are passionate
              about our brands, our products, our customers, and our people,
              because they are the reason we are here, and that is what makes us
              successful.
            </p>
            <Link to="/about">
              <button onClick={scrollTop} className="btn_two">
                Read more{" "}
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className="__container">
        <div className="__certificate"></div>
      </div>
      <Partners />
    </div>
  );
};

export default Home;
