import React, { useEffect, useState } from "react";
import './Partners.scss'


const Partners = () => {
    const [isActive, setIsActive] = useState({
      id: "divOne",
    });
    useEffect(() => {
      // console.log(isActive);
    }, [isActive]);
    const hideShowDiv = (e) => {
      setIsActive({
        id: e.target.id,
      });
    };
  return (
    <div className="__partners __container">
      <h1>
        Our <span>partners</span>
      </h1>
      <div
        className="button-section"
        style={{
          maxWidth: "1100px",
        }}
      >
        <div className="btn-one">
          <button
            id="divOne"
            onClick={(e) => {
              hideShowDiv(e);
            }}
            className="primary-btn-why"
          >
          Retail
          </button>
        </div>
        <div className="btn-two">
          <button
            id="divTwo"
            onClick={(e) => {
              hideShowDiv(e);
            }}
            className="primary-btn-why"
          >
            Global FMCG
          </button>
        </div>
        <div className="btn-three">
          <button
            id="divThree"
            onClick={(e) => {
              hideShowDiv(e);
            }}
            className="primary-btn-why"
          >
            Entertainment
          </button>
        </div>
      </div>
      <div className="__container">
        <div
          className={isActive.id === "divOne" ? `divOne` : "divOne d-none  "}
        >
          <div className="__logos">
            <div className="__logo">
              <img src={require("../../img/l15.webp")} />
            </div>
            <div className="__logo">
              <img src={require("../../img/l11.webp")} />
            </div>
            <div className="__logo">
              <img src={require("../../img/l12.webp")} />
            </div>
            <div className="__logo">
              <img src={require("../../img/l13.webp")} />
            </div>
            <div className="__logo">
              <img src={require("../../img/l14.webp")} />
            </div>
            <div className="__logo">
              <img src={require("../../img/l10.webp")} />
            </div>
            <div className="__logo">
              <img src={require("../../img/l16.webp")} />
            </div>
            <div className="__logo">
              <img src={require("../../img/l17.webp")} />
            </div>
            <div className="__logo">
              <img src={require("../../img/l18.webp")} />
            </div>
          </div>
        </div>
        <div className={isActive.id === "divTwo" ? `divTwo` : "divTwo d-none "}>
          <div className="__logos">
            <div className="__logo">
              <img src={require("../../img/l1.webp")} />
            </div>
            <div className="__logo">
              <img src={require("../../img/l2.webp")} />
            </div>
            <div className="__logo">
              <img src={require("../../img/l4.webp")} />
            </div>
            <div className="__logo">
              <img src={require("../../img/l3.webp")} />
            </div>
            <div className="__logo">
              <img src={require("../../img/l5.webp")} />
            </div>
            <div className="__logo">
              <img src={require("../../img/l6.webp")} />
            </div>
            <div className="__logo">
              <img src={require("../../img/l8.webp")} />
            </div>
            <div className="__logo">
              <img src={require("../../img/l7.webp")} />
            </div>
            <div className="__logo">
              <img src={require("../../img/l9.webp")} />
            </div>
          </div>
        </div>
        <div
          className={
            isActive.id === "divThree" ? `divThree` : "divThree d-none "
          }
        >
          <div className="__logos">
            <div className="__logo">
              <img src={require("../../img/l19.webp")} />
            </div>{" "}
            <div className="__logo">
              <img src={require("../../img/l20.webp")} />
            </div>{" "}
            <div className="__logo">
              <img src={require("../../img/l25.webp")} />
            </div>{" "}
            <div className="__logo">
              <img src={require("../../img/l26.webp")} />
            </div>{" "}
            <div className="__logo">
              <img src={require("../../img/l21.webp")} />
            </div>{" "}
            <div className="__logo">
              <img src={require("../../img/l22.webp")} />
            </div>{" "}
            <div className="__logo">
              <img src={require("../../img/l23.webp")} />
            </div>{" "}
            <div className="__logo">
              <img src={require("../../img/l24.webp")} />
            </div>{" "}
            <div className="__logo">
              <img src={require("../../img/l27.webp")} />
            </div>
            <div className="__logo">
              <img src={require("../../img/l28.webp")} />
            </div>
            <div className="__logo">
              <img src={require("../../img/l30.webp")} />
            </div>
            <div className="__logo">
              <img src={require("../../img/l29.webp")} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partners;
