import { useState } from "react";
import "./NavBar.css";
import { Link } from "react-router-dom";
import UnopDropdown from "unop-react-dropdown";
import EmailIcon from "@mui/icons-material/Email";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";


const NavBar = () => {


  const scrollTop = ()=>{
    window.scrollTo(0,0)
  }


  return (
    <div className=" __navBar">
      <div className="__container">
        <nav class="navbar navbar-expand-lg bg-body-tertiary">
          <div class="container-fluid">
            <Link class="navbar-brand" to="/">
              <div className="__items_one">
                <img src={require("../../img/logo.webp")} />
              </div>
            </Link>

            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="toggler-icon top-bar"></span>
              <span class="toggler-icon middle-bar"></span>
              <span class="toggler-icon bottom-bar"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarNavDropdown">
              <ul class="navbar-nav ms-auto">
                <li class="nav-item">
                  <Link onClick={scrollTop} class="nav-link" to="/about">
                    About Us
                  </Link>
                </li>
                <li class="nav-item dropdown">
                  <a
                    class="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Categories
                  </a>
                  <ul class="dropdown-menu">
                    <li>
                      <Link
                        onClick={scrollTop}
                        class="dropdown-item"
                        to="/confectionery"
                      >
                        Confectionery
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={scrollTop}
                        class="dropdown-item"
                        to="/snacks"
                      >
                        Snacks
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={scrollTop}
                        class="dropdown-item"
                        to="/baking"
                      >
                        Baking
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={scrollTop}
                        class="dropdown-item"
                        to="/malban"
                      >
                        Malban
                      </Link>
                    </li>
                  </ul>
                </li>

                {/* <li class="nav-item dropdown">
                  <a
                    class="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Brands
                  </a>
                  <ul class="dropdown-menu">
                    <li>
                      <Link
                        onClick={scrollTop}
                        class="dropdown-item"
                        to="/confectionery_brands"
                      >
                        Confectionery
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={scrollTop}
                        class="dropdown-item"
                        to="/snacks_brand"
                      >
                        Snacks
                      </Link>
                    </li>
                  </ul>
                </li> */}
                <li class="nav-item">
                  <Link onClick={scrollTop} class="nav-link" to="/brands">
                    Brands
                  </Link>
                </li>

                <li class="nav-item">
                  <Link onClick={scrollTop} class="nav-link" to="/factories">
                    Factories
                  </Link>
                </li>
                {/* 
                <li class="nav-item dropdown">
                  <Link
                    onClick={scrollTop}
                    class="nav-link dropdown-toggle"
                    to="/factories"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Factories
                  </Link>
                  <ul class="dropdown-menu">
                    <li>
                      <Link
                        onClick={scrollTop}
                        class="dropdown-item"
                        to="/factories"
                      >
                        Factories
                      </Link>
                    </li>
                    <li
                      style={{
                        paddingLeft: "20px",
                      }}
                    >
                      <Link
                        onClick={scrollTop}
                        class="dropdown-item"
                        to="/notions"
                      >
                        Notions
                      </Link>
                    </li>
                    <li style={{ paddingLeft: "20px" }}>
                      <Link
                        onClick={scrollTop}
                        class="dropdown-item"
                        to="/laronda"
                      >
                        Laronda
                      </Link>
                    </li>
                    <li style={{ paddingLeft: "20px" }}>
                      <Link
                        onClick={scrollTop}
                        class="dropdown-item"
                        to="/starfood"
                      >
                        Star food
                      </Link>
                    </li>
                  </ul>
                </li> */}

                <li class="nav-item">
                  <Link
                    onClick={scrollTop}
                    class="nav-link"
                    to="/private_labels"
                  >
                    Private Labels
                  </Link>
                </li>

                <li class="nav-item">
                  <Link onClick={scrollTop} class="nav-link" to="/export">
                    Exports
                  </Link>
                </li>
                <li class="nav-item">
                  <Link onClick={scrollTop} class="nav-link" to="/dutyfree">
                    Travel Retail
                  </Link>
                </li>

                <li class="nav-item">
                  <Link
                    onClick={scrollTop}
                    class="nav-link"
                    to="/index.php/catalog"
                  >
                    Catalogue
                  </Link>
                </li>
                <li class="nav-item">
                  <a
                    href="https://shop.notionsgroup.com/products/1"
                    class="nav-link"
                  >
                    Shop
                  </a>
                </li>

                <li class="nav-item">
                  <Link onClick={scrollTop} class="nav-link" to="/contact">
                    <EmailIcon />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default NavBar;
