import React, { useState } from "react";
import "./SubBrands.scss";

const SubBrands = () => {
  const [isShowMore_A, setIsShowMore_A] = useState(false);
  const [isShowMore_B, setIsShowMore_B] = useState(false);
  const [isShowMore_C, setIsShowMore_C] = useState(false);
  const [isShowMore_D, setIsShowMore_D] = useState(false);
  const [isShowMore_E, setIsShowMore_E] = useState(false);
  const [isShowMore_F, setIsShowMore_F] = useState(false);
  const [isShowMore_G, setIsShowMore_G] = useState(false);
  const [isShowMore_H, setIsShowMore_H] = useState(false);
  const [isShowMore_I, setIsShowMore_I] = useState(false);
  const [isShowMore_J, setIsShowMore_J] = useState(false);
  const [isShowMore_K, setIsShowMore_K] = useState(false);
  const [isShowMore_L, setIsShowMore_L] = useState(false);
  const [isShowMore_M, setIsShowMore_M] = useState(false);
  const [isShowMore_N, setIsShowMore_N] = useState(false);
  const [isShowMore_O, setIsShowMore_O] = useState(false);
  const [isShowMore_P, setIsShowMore_P] = useState(false);
  const [isShowMore_Q, setIsShowMore_Q] = useState(false);
  const [isShowMore_R, setIsShowMore_R] = useState(false);
  const [isShowMore_S, setIsShowMore_S] = useState(false);
  const [isShowMore_T, setIsShowMore_T] = useState(false);
  const [isShowMore_U, setIsShowMore_U] = useState(false);
  const [isShowMore_V, setIsShowMore_V] = useState(false);
  const [isShowMore_W, setIsShowMore_W] = useState(false);
  const [isShowMore_X, setIsShowMore_X] = useState(false);
  const [isShowMore_Y, setIsShowMore_Y] = useState(false);
  const [isShowMore_Z, setIsShowMore_Z] = useState(false);
  const [isShowMore_AA, setIsShowMore_AA] = useState(false);
  const [isShowMore_AB, setIsShowMore_AB] = useState(false);
  const [isShowMore_AC, setIsShowMore_AC] = useState(false);

  const toggleReadMoreLess_A = () => {
    setIsShowMore_A(!isShowMore_A);
  };
  const toggleReadMoreLess_B = () => {
    setIsShowMore_B(!isShowMore_B);
  };
  const toggleReadMoreLess_C = () => {
    setIsShowMore_C(!isShowMore_C);
  };
  const toggleReadMoreLess_D = () => {
    setIsShowMore_D(!isShowMore_D);
  };
  const toggleReadMoreLess_E = () => {
    setIsShowMore_E(!isShowMore_E);
  };
  const toggleReadMoreLess_F = () => {
    setIsShowMore_F(!isShowMore_F);
  };
  const toggleReadMoreLess_G = () => {
    setIsShowMore_G(!isShowMore_G);
  };
  const toggleReadMoreLess_H = () => {
    setIsShowMore_H(!isShowMore_H);
  };
  const toggleReadMoreLess_I = () => {
    setIsShowMore_I(!isShowMore_I);
  };
  const toggleReadMoreLess_J = () => {
    setIsShowMore_J(!isShowMore_J);
  };
  const toggleReadMoreLess_K = () => {
    setIsShowMore_K(!isShowMore_K);
  };
  const toggleReadMoreLess_L = () => {
    setIsShowMore_L(!isShowMore_L);
  };
  const toggleReadMoreLess_M = () => {
    setIsShowMore_M(!isShowMore_M);
  };
  const toggleReadMoreLess_N = () => {
    setIsShowMore_N(!isShowMore_N);
  };
  const toggleReadMoreLess_O = () => {
    setIsShowMore_O(!isShowMore_O);
  };
  const toggleReadMoreLess_P = () => {
    setIsShowMore_P(!isShowMore_P);
  };
  const toggleReadMoreLess_Q = () => {
    setIsShowMore_Q(!isShowMore_Q);
  };
  const toggleReadMoreLess_R = () => {
    setIsShowMore_R(!isShowMore_R);
  };
  const toggleReadMoreLess_S = () => {
    setIsShowMore_S(!isShowMore_S);
  };
  const toggleReadMoreLess_T = () => {
    setIsShowMore_T(!isShowMore_T);
  };
  const toggleReadMoreLess_U = () => {
    setIsShowMore_U(!isShowMore_U);
  };
  const toggleReadMoreLess_V = () => {
    setIsShowMore_V(!isShowMore_V);
  };

  const toggleReadMoreLess_W = () => {
    setIsShowMore_W(!isShowMore_W);
  };

  const toggleReadMoreLess_X = () => {
    setIsShowMore_X(!isShowMore_X);
  };

  const toggleReadMoreLess_Y = () => {
    setIsShowMore_Y(!isShowMore_Y);
  };

  const toggleReadMoreLess_Z = () => {
    setIsShowMore_Z(!isShowMore_Z);
  };

  const toggleReadMoreLess_AA = () => {
    setIsShowMore_AA(!isShowMore_AA);
  };

  const toggleReadMoreLess_AB = () => {
    setIsShowMore_AB(!isShowMore_AB);
  };

  const toggleReadMoreLess_AC = () => {
    setIsShowMore_AC(!isShowMore_AC);
  };

  return (
    <div className="sub_confectionery">
      <div className="__container">
        <div className="__sub__con_head">
          <img src={require("../../img/xl.webp")} />
          <br />
          <p>
            Snacking Division under Notions group is run by Star Food
            Industries. Established in 1995, Star food is the only company in
            the Saudi Arabian Kingdom that produces all the categories of Snack
            Foods.
          </p>
          {isShowMore_A && (
            <p>
              {" "}
              In addition to the 27 years of experience in the production of
              snack foods, Star Food is the only snack food pallet producers
              amongst the top players, other competitors import pallets for the
              production of palleted chips. In addition to being HACCP
              Certified, Star food has recently upgraded and acquired the
              certification for its quality control These two certifications are
              globally recognized and show strict adherence to product safety
              and high-quality standards which are essential for the sustainable
              growth of any establishment.
            </p>
          )}
          <button onClick={toggleReadMoreLess_A}>
            {isShowMore_A ? "Read Less" : "Read More"}
          </button>
        </div>
        <div className="__sub__con_main_cat">
          <a href="#1">
            <div className="__sub__con_cat_card">
              <div className="__sub__con_cat_img">
                <img src={require("../../img/XL Ketchup.png")} />
              </div>
              <div className="__sub__con__cat_text">
                <h2>Fresh Potato Chips​</h2>​
              </div>
            </div>
          </a>

          <a href="#2">
            <div className="__sub__con_cat_card">
              <div className="__sub__con_cat_img">
                <img src={require("../../img/Xtreme CD.png")} />
              </div>
              <div className="__sub__con__cat_text">
                <h2>Xtreme​</h2>​
              </div>
            </div>
          </a>

          <a href="#3">
            <div className="__sub__con_cat_card">
              <div className="__sub__con_cat_img">
                <img src={require("../../img/XL Baked Jal.png")} />
              </div>
              <div className="__sub__con__cat_text">
                <h2>Baked​​</h2>​
              </div>
            </div>
          </a>

          <a href="#4">
            <div className="__sub__con_cat_card">
              <div className="__sub__con_cat_img">
                <img src={require("../../img/Twister BBQ.png")} />
              </div>
              <div className="__sub__con__cat_text">
                <h2>Twister - Corn Snacks​​</h2>​
              </div>
            </div>
          </a>
          <a href="#5">
            <div className="__sub__con_cat_card">
              <div className="__sub__con_cat_img">
                <img src={require("../../img/Sticks Pouch MS.webp")} />
              </div>
              <div className="__sub__con__cat_text">
                <h2>Sticks​​​​​</h2>​
              </div>
            </div>
          </a>

          <a href="#6">
            <div className="__sub__con_cat_card">
              <div className="__sub__con_cat_img">
                <img src={require("../../img/Twinkel Papper Black.png")} />
              </div>
              <div className="__sub__con__cat_text">
                <h2>XL - Tiwinkle Ketchup​</h2>​
              </div>
            </div>
          </a>
        </div>
      </div>

      <div style={{ backgroundColor: "#f9f9f9", width: "100vw" }}>
        <div className="sub__conf_wrap_one __container" id="1">
          <div className="sub__conf_img">
            <img src={require("../../img/xl_pkg_1.webp")} />
          </div>
          <div className="sub__conf_txt">
            <div className="sub__conf_text_head">
              <h1>XL - Fresh Potato Chips​​</h1>
              <h3>Unleash the Extra in Every Bite</h3>
            </div>
            <p>
              Our brand is the embodiment of living life to its absolute
              fullest, with a crunch that's louder than the rest, flavors that
              explode over the top and a size that screams extreme.
            </p>
            {isShowMore_B && (
              <p>
                For those who believe in seizing life with both hands and
                breaking norms, our chips are not just an extra-large snack, but
                a statement of an extra-intense lifestyle.
                <br />
                <br />
                With XL, you're not just eating, you're making a statement. Go
                beyond the ordinary, live with extreme gusto - Choose XL!
                <br />
                <br />
                ​ XL chips, deliciously refined in flavor with perfect crunch
                made with freshly sourced potatoes.
                <br />
              </p>
            )}
            <button onClick={toggleReadMoreLess_B}>
              {isShowMore_B ? "Read Less" : "Read More"}
            </button>
          </div>
        </div>
      </div>

      <div className=" sub_wrap  __container">
        <div className="sub__conf_wrap" id="2">
          <div className="sub__conf_txt">
            <div className="sub__conf_text_head">
              <h1>XL -  Xtreme​</h1>
              <h2>Take Your Snacking to the Next Level!​</h2>
            </div>
            <p>
              Love our XL Fresh Potato Chips? Prepare to elevate your snacking
              game with our newest addition: XL - Xtreme. Unlike the classic
              smooth texture of XL Fresh Potato Chips,
            </p>
            {isShowMore_C && (
              <span>
                <p>
                  XL - Xtreme offers a wavy crinkle-cut design, crafted to hold
                  in more flavors and offer an intensified crunch. These chips
                  don't just satisfy your taste buds; they provide an all-around
                  sensorial experience that’s as fun as it is flavorful.​
                </p>
                <h3>Flavor-Soaked Waves</h3>
                <p>
                  The crinkle-cut design of XL - Xtreme provides a richer, more
                  satisfying crunch​
                </p>
                <h3>Flavor-Soaked Waves</h3>
                <p>
                  The wavy grooves are crafted to trap more seasoning, making
                  each XL - Xtreme chip a pocket of intense flavor.​
                </p>
                <h3>Elevated Experience</h3>
                <p>
                  With more complex flavors and a more textured crunch, XL -
                  Xtreme is for those looking to take their snacking to the next
                  level.​
                </p>
              </span>
            )}
            <button onClick={toggleReadMoreLess_C}>
              {isShowMore_C ? "Read Less" : "Read More"}
            </button>
          </div>

          <div className="sub__conf_img">
            <img src={require("../../img/x_trm.webp")} />
          </div>
        </div>

        <div className="sub__sub__text">
          <h2>Flavorful Adventures Await</h2>
          <div className="sub_sub_in_wrap">
            <div className="sub_sub_in_wrap_sec">
              <img src={require("../../img/x_03.webp")} />
              <h3>Flaming Hot</h3>
              {isShowMore_D && (
                <p>
                  Add some sizzle to your snacking with Flaming Hot XL - Xtreme.
                  Seasoned with a blend of fiery spices, each wavy chip delivers
                  a punch of heat that is not for the faint of heart. It’s the
                  perfect choice for those who like their snacks as adventurous
                  as they are.
                </p>
              )}
              <button onClick={toggleReadMoreLess_D}>
                {isShowMore_D ? "Read Less" : "Read More"}
              </button>
            </div>
            <div className="sub_sub_in_wrap_sec">
              <img src={require("../../img/x_02.webp")} />
              <h3>Creamy Cheddar</h3>
              {isShowMore_E && (
                <p>
                  Indulge in the velvety richness of our Creamy Cheddar XL -
                  Xtreme. Each crinkle-cut chip is generously coated with a
                  cheddar flavor that’s so creamy, it'll make you forget you're
                  eating a chip and not a slice of fine cheese.
                </p>
              )}
              <button onClick={toggleReadMoreLess_E}>
                {isShowMore_E ? "Read Less" : "Read More"}
              </button>
            </div>
            <div className="sub_sub_in_wrap_sec">
              <img src={require("../../img/x_01.webp")} />
              <h3>Thai Sweet Chili:</h3>
              {isShowMore_F && (
                <p>
                  Experience the exotic with Thai Sweet Chili XL - Xtreme. The
                  unique blend of spicy chili and sweet undertones offers a
                  taste adventure that transports you straight to the streets of
                  Thailand. Each bite is a balance of heat and sweetness, making
                  this an irresistibly complex flavor.
                </p>
              )}
              <button onClick={toggleReadMoreLess_F}>
                {isShowMore_F ? "Read Less" : "Read More"}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div style={{ backgroundColor: "#f9f9f9" }}>
        <div className=" sub_wrap  __container">
          <div className="sub__conf_wrap" id="3">
            <div className="sub__conf_img">
              <img src={require("../../img/bk_i.webp")} />
            </div>

            <div className="sub__conf_txt">
              <div className="sub__conf_text_head">
                <h1>XL -  Baked​</h1>
                <h2>Snacking shouldn't come with guilt</h2>
              </div>
              <p>
                Snacking shouldn't come with guilt, and that’s why we proudly
                introduce XL - Baked, the all-new, healthier variant of our
                beloved XL Fresh Potato Chips.
              </p>
              {isShowMore_G && (
                <span>
                  <p>
                    Expertly baked to perfection rather than fried, these chips
                    deliver all the taste you love with significantly less fat.
                    It's the ultimate win-win—pure snacking satisfaction without
                    the calorie overload!
                  </p>
                  <h3>Lower in Fat</h3>
                  <p>
                    By baking instead of frying, we significantly reduce the fat
                    content without compromising on flavor or crunch
                  </p>
                  <h3>Guilt-Free Indulgence</h3>
                  <p>
                    Now you can enjoy your favorite XL chips without worrying
                    about your waistline. Our baked chips offer a healthier
                    alternative without sacrificing taste.
                  </p>
                  <h3>Texture and Flavor</h3>
                  <p>
                    Our innovative baking process ensures that each chip still
                    offers that satisfying crunch, all while soaking in our
                    gourmet flavors more effectively.​
                  </p>
                  <p>
                    Indulge in the luxury of choice with XL - Baked, where
                    health-conscious snacking meets exquisite flavor. It's not
                    just a better chip; it's a better way to snack!
                  </p>
                </span>
              )}
              <button onClick={toggleReadMoreLess_G}>
                {isShowMore_G ? "Read Less" : "Read More"}
              </button>
            </div>
          </div>

          <div className="sub__sub__text">
            <h2>Intriguing Flavors to Savor</h2>
            <div className="sub_sub_in_wrap">
              <div className="sub_sub_in_wrap_sec">
                <img src={require("../../img/bk_1.webp")} />
                <h3>Jalapeno Basil</h3>
                {isShowMore_H && (
                  <p>
                    Spice meets sophistication with our Jalapeno Basil XL -
                    Baked chips. The heat of jalapeno peppers perfectly
                    complements the aromatic hint of basil, offering a flavor
                    profile that's as layered as it is delicious.
                  </p>
                )}
                <button onClick={toggleReadMoreLess_H}>
                  {isShowMore_H ? "Read Less" : "Read More"}
                </button>
              </div>
              <div className="sub_sub_in_wrap_sec">
                <img src={require("../../img/bk_3.webp")} />
                <h3>Black Pepper</h3>
                {isShowMore_I && (
                  <p>
                    Elevate your snacking game with our Black Pepper XL - Baked
                    chips. Infused with a subtle kick of freshly ground black
                    pepper, these chips satisfy both your salty and spicy
                    cravings in a uniquely flavorful way
                  </p>
                )}
                <button onClick={toggleReadMoreLess_I}>
                  {isShowMore_I ? "Read Less" : "Read More"}
                </button>
              </div>
              <div className="sub_sub_in_wrap_sec">
                <img src={require("../../img/bk_2.webp")} />
                <h3>Vintage Cheese</h3>
                {isShowMore_J && (
                  <p>
                    Step into a world of classic culinary delight with our
                    Vintage Cheese XL - Baked chips. Coated with the finest aged
                    cheese seasoning, each chip is a bite-sized explosion of
                    rich, cheesy goodness.
                  </p>
                )}
                <button onClick={toggleReadMoreLess_J}>
                  {isShowMore_J ? "Read Less" : "Read More"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className=" sub_wrap  __container">
        <div className="sub__conf_wrap" id="4">
          <div className="sub__conf_txt">
            <div className="sub__conf_text_head">
              <h1>Twister - Corn Snacks​​</h1>
              <h2>Take Your Snacking to the Next Level!​</h2>
            </div>
            <p>
              In the quest to create snacks that are not just delicious but also
              delightfully fun, Notions Group presents "Twisters."
            </p>
            {isShowMore_T && (
              <p>
                These aren't your average extruded corn snacks; Twisters come
                with a literal "twist" in shape, elevating your snacking
                experience to a whole new level. Crafted with precision and
                seasoned to perfection, each Twister offers an exciting burst of
                flavor that tantalizes your taste buds.
              </p>
            )}
            <button onClick={toggleReadMoreLess_T}>
              {isShowMore_T ? "Read Less" : "Read More"}
            </button>
          </div>

          <div className="sub__conf_img">
            <img src={require("../../img/Corn.webp")} />
          </div>
        </div>

        <div className="sub__sub__text">
          <h2>Flavorful Adventures Await</h2>
          <div className="sub_sub_in_wrap">
            <div className="sub_sub_in_wrap_sec">
              <img src={require("../../img/co_2.webp")} />
              <h3>Nacho Cheese</h3>
              {isShowMore_U && (
                <p>
                  Experience the rich, creamy goodness of our Nacho Cheese
                  Twisters. Every twist is packed with a cheesy punch that makes
                  you come back for more. Ideal for those moments when you crave
                  something zesty, these Twisters are a cheese lover's dream
                  come true
                </p>
              )}
              <button onClick={toggleReadMoreLess_U}>
                {isShowMore_U ? "Read Less" : "Read More"}
              </button>
            </div>
            <div className="sub_sub_in_wrap_sec">
              <img src={require("../../img/co_1.webp")} />
              <h3>Himalayan Salt</h3>
              {isShowMore_V && (
                <p>
                  Step into a world of simplicity and elegance with our
                  Himalayan Salt Twisters. Seasoned with ethically-sourced
                  Himalayan pink salt, each twist offers a crisp, clean flavor
                  that celebrates the purity of natural ingredients. It's the
                  perfect snack for those who appreciate the finer things in
                  life.
                </p>
              )}
              <button onClick={toggleReadMoreLess_V}>
                {isShowMore_V ? "Read Less" : "Read More"}
              </button>
            </div>
            <div className="sub_sub_in_wrap_sec">
              <img src={require("../../img/co_3.webp")} />
              <h3>Honey Barbeque</h3>
              {isShowMore_W && (
                <p>
                  Take your taste buds on a roller coaster ride with our Honey
                  Barbeque Twisters. The sweetness of honey is beautifully
                  balanced by the smokiness of barbeque seasoning, offering a
                  multi-layered taste sensation that leaves you craving for just
                  one more twist.
                </p>
              )}
              <button onClick={toggleReadMoreLess_W}>
                {isShowMore_W ? "Read Less" : "Read More"}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div style={{ backgroundColor: "#f9f9f9" }}>
        <div className=" sub_wrap  __container">
          <div className="sub__conf_wrap" id="3">
            <div className="sub__conf_img">
              <img src={require("../../img/stick_wall.webp")} />
            </div>

            <div className="sub__conf_txt">
              <div className="sub__conf_text_head">
                <h1>XL -  Sticks​</h1>
                <h2>Snacking shouldn't come with guilt</h2>
              </div>
              <p>
                Snacking shouldn't come with guilt, and that’s why we proudly
                introduce XL - Baked, the all-new,
              </p>
              {isShowMore_X && (
                <p>
                  healthier variant of our beloved XL Fresh Potato Chips.
                  Expertly baked to perfection rather than fried, these chips
                  deliver all the taste you love with significantly less fat.
                  It's the ultimate win-win—pure snacking satisfaction without
                  the calorie overload!
                </p>
              )}
              <button onClick={toggleReadMoreLess_X}>
                {isShowMore_X ? "Read Less" : "Read More"}
              </button>
            </div>
          </div>

          <div className="sub__sub__text">
            <h2>Intriguing Flavors to Savor</h2>
            <div className="sub_sub_in_wrap">
              <div className="sub_sub_in_wrap_sec">
                <img src={require("../../img/stk_1.webp")} />
                <h3>Mild Spicy</h3>
                {isShowMore_Y && (
                  <p>
                    Spice meets sophistication with our Jalapeno Basil XL -
                    Baked chips. The heat of jalapeno peppers perfectly
                    complements the aromatic hint of basil, offering a flavor
                    profile that's as layered as it is delicious.
                  </p>
                )}
                <button onClick={toggleReadMoreLess_Y}>
                  {isShowMore_Y ? "Read Less" : "Read More"}
                </button>
              </div>
              <div className="sub_sub_in_wrap_sec">
                <img src={require("../../img/stk_2.webp")} />
                <h3>Thai Campfire</h3>
                {isShowMore_Z && (
                  <p>
                    Elevate your snacking game with our Black Pepper XL - Baked
                    chips. Infused with a subtle kick of freshly ground black
                    pepper, these chips satisfy both your salty and spicy
                    cravings in a uniquely flavorful way
                  </p>
                )}
                <button onClick={toggleReadMoreLess_Z}>
                  {isShowMore_Z ? "Read Less" : "Read More"}
                </button>
              </div>
              <div className="sub_sub_in_wrap_sec">
                <img src={require("../../img/stk_3.webp")} />
                <h3>Tomato</h3>
                {isShowMore_AA && (
                  <p>
                    Step into a world of classic culinary delight with our
                    Vintage Cheese XL - Baked chips. Coated with the finest aged
                    cheese seasoning, each chip is a bite-sized explosion of
                    rich, cheesy goodness.
                  </p>
                )}
                <button onClick={toggleReadMoreLess_AA}>
                  {isShowMore_AA ? "Read Less" : "Read More"}
                </button>
              </div>
              <div className="sub_sub_in_wrap_sec">
                <img src={require("../../img/stk_4.webp")} />
                <h3>Original</h3>
                {isShowMore_AB && (
                  <p>
                    Step into a world of classic culinary delight with our
                    Vintage Cheese XL - Baked chips. Coated with the finest aged
                    cheese seasoning, each chip is a bite-sized explosion of
                    rich, cheesy goodness.
                  </p>
                )}
                <button onClick={toggleReadMoreLess_AB}>
                  {isShowMore_AB ? "Read Less" : "Read More"}
                </button>
              </div>
              <div className="sub_sub_in_wrap_sec">
                <img src={require("../../img/stk_5.webp")} />
                <h3>Sea Salt</h3>
                {isShowMore_AC && (
                  <p>
                    Step into a world of classic culinary delight with our
                    Vintage Cheese XL - Baked chips. Coated with the finest aged
                    cheese seasoning, each chip is a bite-sized explosion of
                    rich, cheesy goodness.
                  </p>
                )}
                <button onClick={toggleReadMoreLess_AC}>
                  {isShowMore_AC ? "Read Less" : "Read More"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubBrands;
