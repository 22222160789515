
import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
const StarFoodSlidShow = () => {
  return (
    <div>
      <OwlCarousel  className="owl-theme" loop margin={10} items={1} nav>
        <div class="item">
          <img src={require("../../img/star fm/Factory-16.webp")} />
        </div>
        <div class="item">
          <img src={require("../../img/star fm/Factory2-01.webp")} />
        </div>
        <div class="item">
          <img src={require("../../img/star fm/Factory2-02.webp")} />
        </div>
        <div class="item">
          <img src={require("../../img/star fm/Factory2-03.webp")} />
        </div>
        <div class="item">
          <img src={require("../../img/star fm/Factory2-04.webp")} />
        </div>
        <div class="item">
          <img src={require("../../img/star fm/Factory-18.webp")} />
        </div>

      </OwlCarousel>
    </div>
  );
}

export default StarFoodSlidShow
