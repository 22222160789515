import React, { useState } from "react";
import "./OurExport.scss";
import exp_img from "../../img/export.webp";
import WorldMap from "../../components/world_map/WorldMap";
import Form from "../../components/form/Form";

const OurExport = () => {
  const [formData, setFormData] = useState({
    titel: "Export",
    first: "",
    last: "",
    phone: "",
    email: "",
    country: "",
    message: "",
    company: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form data submitted:", formData);

    setFormData({
      first: "",
      last: "",
      phone: "",
      email: "",
      country: "",
      message: "",
      company: "",
    });
  };

  return (
    <div className="__export">
      <div
        className="__export_wrap"
        style={{
          backgroundImage: `url(${exp_img})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <h1>Export</h1>
      </div>
      <div className="__container">
        <div className="__export_container">
          <div className="__exp_con">
            <h1>
              Global <span>Markets</span> ​
            </h1>
          </div>
          <div className="__exp_con_num">
            <div className="__exp_con_in">
              <div className="__exp_in">
                <h1> 50+</h1>
              </div>
              <div className="__exp_in">
                <span>Countries</span>
              </div>
            </div>
            <p>
              Notions Global growing strong every year by adding a new regions
              and countries. Currently, the Arabian Delights and Chocodate is
              loved by people across 5 continents​
            </p>
          </div>
        </div>

        <div className="__exp_map">
          <WorldMap />
        </div>
      </div>
      <div className="__exp_round_con">
        <div className="__container">
          <div className="__exp__wrap">
            <div className="__exp__round">
              <div className="__exp__r_in">
                <div className="__exp_r_in_img">
                  <img src={require("../../img/28.webp")} />
                </div>
                <div className="__exp_r_in_text">
                  <h3>Avg Value Growth Rate over 2 years​</h3>
                </div>
              </div>

              <div className="__exp__r_in">
                <div className="__exp_r_in_img">
                  <img src={require("../../img/1000.webp")} />
                </div>
                <div className="__exp_r_in_text">
                  <h3>Chocodates & Chocolates Exported Around the globe ​​</h3>
                </div>
              </div>

              <div className="__exp__r_in">
                <div className="__exp_r_in_img">
                  <img src={require("../../img/500k.webp")} />
                </div>
                <div className="__exp_r_in_text">
                  <h3>ChocoDates Consumed everyday​</h3>
                </div>
              </div>
            </div>

            <div className="__exp__form__con">
              <div className="__exp__form">
                <Form/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurExport;
