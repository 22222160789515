import React from "react";
import "./OurBrand.scss";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { Link } from "react-router-dom";

const OurBrand = () => {
  const scrollTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="__ourBrand __container">
      <div className="__ourBrand_head">
        <h1>
          Our <span>brands</span>{" "}
        </h1>
        <h2>Passion is the force that drives us</h2>
        <div className="__ourBrand_in">
          <p>
            Reason is what steers our endeavours. We put our hearts and our
            minds in every little detail of the business to achieve the best
            results. We are passionate about our brands, our products, our
            customers, and our people, because they are the reason we are here,
            and that is what makes us successful.
          </p>
        </div>
      </div>

      <div className="__ourBrand_section">
        <div className="__ourBrand_section_img I_a">
          <img src={require("../../img/snack.png")} />
        </div>
        <div className="__ourBrand_section_text I_b">
          <h1>Snacks</h1>
          <h2>Introducing Star Foods Industries</h2>

          <p>
            At the heart of snacking perfection lies Star Foods Industries, a
            pinnacle of dedication, precision, and unparalleled quality. With
            the astounding capacity to produce over 15,200 tons of potato chips
            and snacks annually, we've firmly established our reputation in the
            global snacks industry.
          </p>

          <Link to="/snacks_brand" >
            <button onClick={scrollTop} className="btn">
              Read more
            </button>
          </Link>
        </div>
      </div>

      <div className="__ourBrand_section">
        <div className="__ourBrand_section_text I_c">
          <div>
            <h1>Chocolate</h1>
            <h2>Specialization in Chocodate</h2>

            <p>
              Our pride and joy, Chocodate, embodies the perfect fusion of rich
              chocolate and succulent dates. Created with meticulous care and
              creativity, this guilt-free treat has become a global favorite,
              symbolizing a harmonious blend of traditional flavors and modern
              tastes. ​ ​ ​ ​
            </p>
          </div>

          <Link to="/confectionery_brands">
            <button onClick={scrollTop} className="btn">
              Read more
            </button>
          </Link>
        </div>
        <div className="__ourBrand_section_img I_d">
          <img src={require("../../img/chocolate 2.webp")} />
        </div>
      </div>
    </div>
  );
};

export default OurBrand;
