import React, { useState } from 'react'
import "./brands.scss";
import { Link } from 'react-router-dom';
import "../../pages/confectioneryBrand/ConfectioneryBrand.scss";
import "../../pages/snackBrand/SnacksBrand.scss";

const Brands = () => {

  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  const [isShowMore, setIsShowMore] = useState(false);

  const [isShowMoreFour, setIsShowMoreFour] = useState(false);
  const [isShowMoreA, setIsShowMoreA] = useState(false);
  const [isShowMoreB, setIsShowMoreB] = useState(false);
  const [isShowMoreC, setIsShowMoreC] = useState(false);

  const toggleReadMoreLess = () => {
    setIsShowMore(!isShowMore);
  };

  const toggleReadMoreLess_Four = () => {
    setIsShowMoreFour(!isShowMoreFour);
  };
  const toggleReadMoreA = () => {
    setIsShowMoreA(!isShowMoreA);
  };

  const toggleReadMoreB = () => {
    setIsShowMoreB(!isShowMoreB);
  };

  const toggleReadMoreC = () => {
    setIsShowMoreC(!isShowMoreC);
  };

  const [isShowMore_A, setIsShowMore_A] = useState(false);
  const [isShowMore_B, setIsShowMore_B] = useState(false);
  const [isShowMore_C, setIsShowMore_C] = useState(false);
  const [isShowMoreD, setIsShowMoreD] = useState(false);
  const [isShowMoreE, setIsShowMoreE] = useState(false);
  const [isShowMoreF, setIsShowMoreF] = useState(false);
  const [isShowMoreG, setIsShowMoreG] = useState(false);

  const toggleReadMore_A = () => {
    setIsShowMoreA(!isShowMoreA);
  };
  const toggleReadMore_B = () => {
    setIsShowMoreB(!isShowMoreB);
  };
  const toggleReadMore_C = () => {
    setIsShowMoreC(!isShowMoreC);
  };
  const toggleReadMoreD = () => {
    setIsShowMoreD(!isShowMoreD);
  };
  const toggleReadMoreE = () => {
    setIsShowMoreE(!isShowMoreE);
  };
  const toggleReadMoreF = () => {
    setIsShowMoreF(!isShowMoreF);
  };
  const toggleReadMoreG = () => {
    setIsShowMoreG(!isShowMoreG);
  };






  return (
    <>
      <div className=" brands confectioneryBrand">
        <div className="brandCard">
          <a href="#1">
            <div className="cardItem">
              <div className="cardIn">
                <img src={require("../../img/CD BG.webp")} />
              </div>
            </div>
          </a>
          <a href="#2">
            <div className="cardItem">
              <div className="cardIn">
                <img src={require("../../img/AD BG.webp")} />
              </div>
            </div>
          </a>
          <a href="#3">
            <div className="cardItem">
              <div className="cardIn">
                <img src={require("../../img/WISHES BG.webp")} />
              </div>
            </div>
          </a>
          <a href="#4">
            <div className="cardItem">
              <div className="cardIn">
                <img src={require("../../img/FIESTA BG.webp")} />
              </div>
            </div>
          </a>
          <a href="#5">
            <div className="cardItem">
              <div className="cardIn">
                <img src={require("../../img/LR BG.webp")} />
              </div>
            </div>
          </a>
          <a href="#6">
            <div className="cardItem">
              <div className="cardIn">
                <img src={require("../../img/XL BG.webp")} />
              </div>
            </div>
          </a>
          <a href="#7">
            <div className="cardItem">
              <div className="cardIn">
                <img src={require("../../img/AMIGO BG.webp")} />
              </div>
            </div>
          </a>
          <a href="#8">
            <div className="cardItem">
              <div className="cardIn">
                <img src={require("../../img/Raja BG.webp")} />
              </div>
            </div>
          </a>
        </div>

        <div className="__container">
          <div className="__brand" id="1">
            <div className="__brand_img I_a">
              <img src={require("../../img/choco_d.webp")} />
            </div>
            <div className="__brand_text I_b">
              <img
                style={{ paddingBottom: "30px" }}
                src={require("../../img/choco.webp")}
              />
              <h3>
                CHOCODATE® is an exquisite bite sized delicacy made with 3
                simple ingredients including rich silky Chocolate <br />
              </h3>
              {isShowMoreFour && (
                <p>
                  covering a velvety Arabian Date and filled with a golden
                  roasted Almond After 31 years of rich heritage CHOCODATE® from
                  a hidden gem homegrown in Dubai to become one of the most
                  loved brands with over 500,000 chocodates consumed every day
                  across 70 countries. ​ A hand-made treat crafted with passion
                  and love, using the highest quality ingredients, since its
                  creation 25 years ago under its genuine brand CHOCODATE®.
                  <br />
                  <br />​ CHOCODATE® combines the heritage and modern cultures
                  creating a guilt free indulgence pleasing to every palate
                  across the globe
                </p>
              )}
              <button onClick={toggleReadMoreLess_Four}>
                {isShowMoreFour ? "Read Less" : "Read More"}
              </button>
              <div className="brand_icons">
                <div>
                  <img src={require("../../img/icon_01.webp")} />
                  <p>Industry Pioneer​</p>
                </div>
                <div>
                  <img src={require("../../img/icon_02.webp")} />
                  <p>95% Weighted Distribution in UAE​</p>
                </div>
                <div>
                  <img src={require("../../img/icon_03.webp")} />
                  <p>Market Leader​ 20% Share​</p>
                </div>
                <div>
                  <img src={require("../../img/icon_04.webp")} />
                  <p>Registered Trade Mark​</p>
                </div>
              </div>
              <Link onClick={scrollTop} to="/chocodate" className="btn">
                Explore More
              </Link>
            </div>
          </div>

          <div className="__brand" id='2'>
            <div className="__brand_text I_c">
              <img
                style={{ paddingBottom: "30px" }}
                src={require("../../img/ADD.webp")}
              />
              <h3>
                Arabian Delights is a collection of delicious treats that
                celebrate cultural exchange from around the world.
              </h3>
              {isShowMoreA && (
                <p>
                  ​ They are not only an iconic example of Arabian hospitality
                  but also a tasty healthy snack that reflect the perfect merger
                  of East and West while offering a guilt-free indulgence
                  pleasing to every plate across the globe marking one of the
                  unique manifestations of our beautifully interconnected world.
                </p>
              )}
              <button onClick={toggleReadMoreA}>
                {isShowMoreA ? "Read Less" : "Read More"}
              </button>
              <Link onClick={scrollTop} to="/arabian_delights" className="btn">
                Explore More
              </Link>
            </div>

            <div className="__brand_img I_d">
              <img src={require("../../img/arbian_d.webp")} />
            </div>
          </div>

          <div className="__brand" id='3'>
            <div className="__brand_img I_e">
              <img src={require("../../img/w_pro.webp")} />
            </div>
            <div className="__brand_text I_f">
              <img
                style={{ paddingBottom: "30px" }}
                src={require("../../img/wish_logo.webp")}
              />
              <h3>
                "Wishes" is more than a chocolate collection it's a palette of
                edible emotions, a tapestry of tastes that turns any moment into
                a celebration.
              </h3>
              {isShowMoreB && (
                <p>
                  With its bouquet of nine artisanal blends, "Wishes" is a
                  culinary symphony designed to serenade every palate. Whether
                  it's a soul-warming gift for someone special or the star
                  attraction at your next family gathering, this array of
                  chocolaty delights is a passport to instant joy
                  <br /> <br />
                  From the tactile luxury of its packaging to the curated range
                  of flavors inside, "Wishes" isn't just chocolate—it's an
                  experience waiting to be unwrapped and shared. So why settle
                  for ordinary, when you can wish for extraordinary?
                </p>
              )}
              <button onClick={toggleReadMoreB}>
                {isShowMoreB ? "Read Less" : "Read More"}
              </button>
            </div>
          </div>

          <div className="__brand" id='4'>
            <div className="__brand_text I_i">
              <img
                style={{ paddingBottom: "30px" }}
                src={require("../../img/fiesta_logo.webp")}
              />
              <h3>
                Fiesta new range of raw and coated almonds is a collection of
                marvellous treats loaded with irresistible pleasure
              </h3>
              {isShowMoreC && (
                <p>
                  Carefully selected almonds that are high in vitamin E, fibers,
                  full of antioxidants, nutrients, and all packed in a friendly
                  and convenient sizes making these treats the perfect
                  pick-me-up snacks for everyday thrills
                </p>
              )}
              <button onClick={toggleReadMoreC}>
                {isShowMoreC ? "Read Less" : "Read More"}
              </button>
            </div>

            <div className="__brand_img I_j">
              <img src={require("../../img/fiesta.webp")} />
            </div>
          </div>

          <div className="__brand" id='5'>
            <div className="__brand_img I_e">
              <img src={require("../../img/laron_d.webp")} />
            </div>
            <div className="__brand_text I_f">
              <img
                style={{ paddingBottom: "30px" }}
                src={require("../../img/laronda_logo.webp")}
              />
              <h3>
                La Ronda chocolate-covered nuts start with beautifully browned
                crunchy nuts and finished with a smooth
              </h3>
              {isShowMoreB && (
                <p>
                  bittersweet chocolate shell. These nuts have an impressive
                  gourmet taste and make excellent gifts or party snacks
                </p>
              )}
              <button onClick={toggleReadMoreB}>
                {isShowMoreB ? "Read Less" : "Read More"}
              </button>
            </div>
          </div>

          {/* <div className="__brand">
          <div className="__brand_text I_i">
            <img
              style={{ paddingBottom: "30px" }}
              src={require("../../img/r_logo.webp")}
            />
            <h3>
              "Rouge" isn't just chocolate; it's an opulent affair that elevates
              the senses and dances with your taste buds. Crafted from the crème
              de la crème
            </h3>
            {isShowMoreC && (
              <p>
                of European gourmet cocoa each piece is a lavish embrace infused
                with sumptuous nuts and luscious fruit fillings. It's not just a
                premium chocolate collection; it's an ode to love, a symphony of
                flavors that immortalizes moments and makes them unforgettable.
                <br />
                <br />
                Perfect for bestowing upon your loved ones, "Rouge" offers an
                experience that's as decadent as it is memorable. A treasure
                chest of two divine forms: the "Clusters of Euphoria," an exotic
                mélange of nuts and fruits, and the "Enrobed Nobles," nuts
                adorned in a chocolate gown. Both masterpieces offer a sublime
                sensory escape that leaves an indelible mark on the canvas of
                your memories.
                <br />
                <br />
                Choose "Rouge," and you're not just gifting chocolate—you're
                wrapping up love, luxury, and lasting memories in one
                extraordinary package.
              </p>
            )}
            <button onClick={toggleReadMoreC}>
              {isShowMoreC ? "Read Less" : "Read More"}
            </button>
          </div>

          <div className="__brand_img I_j">
            <img src={require("../../img/Roude.webp")} />
          </div>
        </div> */}
        </div>
      </div>
      <div className="confectioneryBrand">
        <div className="__brand_wrp" style={{ backgroundColor: "#f9f9f9" }}>
          <div className="__container">
            <div className="__brand" id='6'>
              <div className="__brand_img">
                <img src={require("../../img/xl_ban.webp")} />
              </div>
              <div className="__brand_text">
                <img
                  style={{ paddingBottom: "30px" }}
                  src={require("../../img/xl.webp")}
                />
                <h3>
                  Unleash the Extra in Every Bite. Our brand is the embodiment
                  of living life to its absolute fullest, with a crunch that's
                  louder than the rest
                </h3>
                {isShowMoreE && (
                  <p>
                    {" "}
                    flavors that explode over the top and a size that screams
                    extreme. For those who believe in seizing life with both
                    hands and breaking norms, our chips are not just an
                    extra-large snack, but a statement of an extra-intense
                    lifestyle. <br />
                    <br />
                    With XL, you're not just eating, you're making a statement.
                    Go beyond the ordinary, live with extreme gusto - Choose XL!
                    <br />
                    <br />​ XL chips, deliciously refined in flavor with perfect
                    crunch made with freshly sourced potatoes.
                  </p>
                )}
                <button onClick={toggleReadMoreE}>
                  {isShowMoreE ? "Read Less" : "Read More"}
                </button>
                <Link
                  onClick={scrollTop}
                  to="/sub_snack_brands"
                  className="btn"
                >
                  Explore More
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="__brand_wrp" id='7'>
          <div className="__container">
            <div className="__brand">
              <div className="__brand_text">
                <img
                  style={{ paddingBottom: "30px" }}
                  src={require("../../img/amigo.webp")}
                />
                <h3>
                  Welcome Amigo The Flavor Fiesta You've Been Waiting For Who
                  says you need to travel to Mexico to experience the authentic
                  taste of corn tortillas?
                </h3>
                {isShowMoreF && (
                  <p>
                    Say "Hola" to Amigo, the newest member of our snacking
                    portfolio that transports your tastebuds straight to a
                    Mexican fiesta! Combining the natural goodness of corn with
                    the hearty crunch of potatoes, our Amigo tortillas are an
                    exotic adventure in every bite.
                  </p>
                )}
                <button onClick={toggleReadMoreF}>
                  {isShowMoreF ? "Read Less" : "Read More"}
                </button>
              </div>

              <div className="__brand_img">
                <img src={require("../../img/amigo_bn.webp")} />
              </div>
            </div>
            <div className="__brand_info">
              <div>
                <img
                  style={{ paddingBottom: "30px" }}
                  src={require("../../img/am_1.webp")}
                />
                <h2>Sweet Chilli</h2>
              </div>

              <div>
                <img
                  style={{ paddingBottom: "30px" }}
                  src={require("../../img/am_4.webp")}
                />
                <h2>Nacho Cheese</h2>
              </div>

              <div>
                <img
                  style={{ paddingBottom: "30px" }}
                  src={require("../../img/am_3.webp")}
                />
                <h2>Salted</h2>
              </div>

              <div>
                <img
                  style={{ paddingBottom: "30px" }}
                  src={require("../../img/am_5.webp")}
                />
                <h2>Chilli</h2>
              </div>

              <div>
                <img
                  style={{ paddingBottom: "30px" }}
                  src={require("../../img/am_2.webp")}
                />
                <h2>Cheese</h2>
              </div>
            </div>
          </div>
        </div>

        <div className="__brand_wrp" style={{ backgroundColor: "#f9f9f9" }} id='8'>
          <div className="__container">
            <div className="__brand">
              <div className="__brand_img">
                <img src={require("../../img/raja_ban.webp")} />
              </div>
              <div className="__brand_text">
                <img
                  style={{ paddingBottom: "30px" }}
                  src={require("../../img/raja_logo.webp")}
                />
                <h3>
                  A Taste of Tradition, A Crunch of Home For over three decades
                  Raja Potato Crunchies have held a special place in the hearts{" "}
                </h3>

                {isShowMoreG && (
                  <p>
                    and pantries of families across the UAE and KSA. With a
                    legacy that transcends generations, our Salad Chips are more
                    than just a snack; they're a cultural cornerstone that
                    evokes fond memories and nostalgia for those who've grown up
                    munching on them.
                  </p>
                )}
                <button onClick={toggleReadMoreG}>
                  {isShowMoreG ? "Read Less" : "Read More"}
                </button>
              </div>
            </div>
            <div className="__brand_info_raja">
              <div>
                <img
                  style={{ paddingBottom: "30px" }}
                  src={require("../../img/raja_3.webp")}
                />
                <h2>Sour Cream</h2>
              </div>

              <div>
                <img
                  style={{ paddingBottom: "30px" }}
                  src={require("../../img/raja_2.webp")}
                />
                <h2>Salted</h2>
              </div>

              <div>
                <img
                  style={{ paddingBottom: "30px" }}
                  src={require("../../img/raja_1.webp")}
                />
                <h2>Salted</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Brands
