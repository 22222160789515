import React, { useState } from 'react'
import './Malban.scss'

const Malban = () => {

  const [isShowMore, setIsShowMore] = useState(false);

  const toggleReadMoreLess = () => {
    setIsShowMore(!isShowMore);
  };



  return (
    <div className="confectionery_mlb">
      <div className="__container">
        <div className="__con_head">
          <h1>Malban</h1>
          <p>
            The ever expanding multiverse of Notions Confectionary now includes​
          </p>
        </div>
      </div>

      <div>
        <div className="conf_wrap __container" id="2">
          <div className="conf_txt I_a">
            <div className="conf_text_head">
              <h1>Malban</h1>
              <h2>Malban, an Arabian delicacy, is meticulously crafted,</h2>
            </div>
            <p>
              Malban, an Arabian delicacy, is meticulously crafted, weaving
              together natural flavors, nuts,
            </p>
            {isShowMore && (
              <p>
                and high-quality ingredients. A symbol of Middle Eastern
                traditional culture, Malban appeals to a wide audience,
                resonating with both local and international palates.
                <br />
                <br />
                Within the NOTIONS Group, expertise in crafting this unique
                treat thrives under the banner of Notions Manufacturing.
                Boasting state-of-the-art manufacturing units and guided by the
                skilled hands of expert chefs, the company excels in producing
                superior quality Malban, setting a benchmark in the category. It
                is widely present across the country in all retail channels from
                supermarkets to roasteries bakeries and sweet shops.
                <br />
                <br />
                Since pioneering the Malban category in 2020, NOTIONS Group has
                embarked on a journey of culinary innovation. The company has
                successfully introduced an array of recipes, flavors, and
                variants, reflecting a deep understanding of traditional
                craftsmanship blended with modern creativity. This commitment to
                excellence affirms NOTIONS Group's position as a leader in the
                field, continually elevating the art of Malban creation to new
                heights. ​
              </p>
            )}
            <button onClick={toggleReadMoreLess}>
              {isShowMore ? "Read Less" : "Read More"}
            </button>
          </div>
          <div className="conf_img I_b">
            <img src={require("../../img/melban.webp")} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Malban
